import {useEffect, useState} from "react";
import {getCoinInfoMovement, getSearchTokenListAptos} from "../apiRequests";
import {getBlockchainName, getNetwork} from "../utils/sundry";
import {useQuery} from "@apollo/client";
import gql from "graphql-tag";
import {MOVEMENT_TOKEN_LIST, TOKEN_LIST} from "../constants/tokenList";

export function useSearchTokenList(search?: string, update?: any) {
    const [tokenList, setTokenList] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() =>{
        const blockchain = getBlockchainName();

        if(!search) {
            setTokenList([]);
            return;
        }
        setLoading(true);

        if(blockchain === "Aptos") {
            getSearchTokenListAptos(search)
                .then((res: any) => {
                setTokenList(res);
                })
                .finally(() => {setLoading(false)})
        }

        if(blockchain === "Movement") {
            getCoinInfoMovement(search)
                .then((res: any) => {
                    setTokenList([res?.coin_info]);
                })
                .finally(() => {setLoading(false)})
        }

    }, [search, update]);

    return {tokenList, loading};
}