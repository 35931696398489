import {useEffect, useState} from "react";
import {getAccountCoinValue} from "../apiRequests";
import {formatBalance} from "../utils/sundry";

export function useSearchTokensBalance(account?: string, inputTokens?: any) {
    const [tokens, setTokens] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if(!inputTokens || inputTokens?.length === 0) {
            setTokens([]);
            return;
        }
        if(!account) {
            setTokens(inputTokens);
            return;
        }
        setLoading(true);
        addBalanceToTokenList(account, inputTokens).then((res: any) => {
            setTokens(res);
        })
            .finally(() => {setLoading(false)});
    }, [account, inputTokens]);

    return {tokens, loading};
}

async function addBalanceToTokenList(account: string, items: any) {
    const updatedItems = await Promise.all(items.map(async (item: any) => {
        const { coin_type, address, decimals } = item;
        const type = (coin_type || address) || "";


        /*const response = await getAccountCoinValue(account?.address || "", type).catch(() => {
            return {};
        });*/

        try {
            const response = await getAccountCoinValue(account || "", type);
            const value = response?.data?.coin?.value || 0;
            const balance = formatBalance(value, decimals);
            return { ...item, balance };
        } catch (error) {
            return { ...item, balance: 0 };
        }

    }));

    return updatedItems;
}