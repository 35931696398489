import {
    localStorageKeyNetwork, MAINNET_INDEXER_GRAPHQL,
    MAINNET_NODE_URL,
    MOVEMENTLABS_URL,
    MOVEMENT_DEVNET_INDEXER_GRAPHQL,
    MOVEMENT_DEVNET_NODE_URL,
    SWAP_ADDRESS_MOVEMENT_DEVNET,
    TOKEN_DEPLOYER_ADDRESS_MOVEMENT_DEVNET,
    networks, smartContractFunctions,
    SWAP_ADDRESS_MAINNET, SWAP_ADDRESS_MAINNET_OLD, SWAP_ADDRESS_TESTNET, TESTNET_INDEXER_GRAPHQL,
    TESTNET_NODE_URL, TOKEN_DEPLOYER_ADDRESS_MAINNET,
    TOKEN_DEPLOYER_ADDRESS_TESTNET, tokenDeployerFunctions,
} from "../constants/aptos";

export const calculateRate = (amount: string, conversionRate: number) => {
    return Number(amount) * conversionRate;
};

export const numberWithCommas = (number: number, decimal?: number) => {
    if (!decimal) decimal = 8; // default number of decimals, 8

    if (number == 0) return 0.0;

    return Number(number.toFixed(decimal)).toLocaleString("en", {
        minimumFractionDigits: decimal,
    });
};

export const formatBalance = (balance: number, decimals: number) => {
    return balance / 10 ** decimals;
};

export const truncateAddress = (address: string | undefined, start = 6, end = -5) => {
    if (!address) return;
    return `${address.slice(0, start)}...${address.slice(end)}`;
};

export function calculatePriceImpact(initialTokenAPool: number, initialTokenBPool: number, tokenASwapped: number) {
    const constantProduct = initialTokenAPool * initialTokenBPool;
    const newTokenAPool = initialTokenAPool + tokenASwapped;
    const newTokenBPool = constantProduct / newTokenAPool;

    const tokenBReceived = initialTokenBPool - newTokenBPool;
    const marketPricePerTokenB = initialTokenAPool / initialTokenBPool;
    const pricePaidPerTokenB = tokenASwapped / tokenBReceived;

    const priceImpact = (1 - (marketPricePerTokenB / pricePaidPerTokenB)) * 100;

    return priceImpact.toFixed(3);
}

export function getNetwork() {
    const network = localStorage.getItem(localStorageKeyNetwork) || networks[0];
    return network;
}

export function getNetworkLowerCase() {
    const network = getNetwork();
    return network.toLowerCase();
}

export function getBlockchainName() {
    const network = getNetwork();
    if(network === "Movement Devnet") return "Movement";
    return "Aptos";
}

export function getNetworkAptos() {
    const network = getNetwork();
    if(network === "Mainnet") return "mainnet";
    if(network === "Mainnet (old)") return "mainnet";
    if(network === "Testnet") return "testnet";
    if(network === "Movement Devnet") return "Movement Devnet";
    return "mainnet";
}

export function getNetworkId() {
    const network = getNetwork();
    if(network === "Mainnet") return "mainnet2";
    if(network === "Mainnet (old)") return "mainnet";
    if(network === "Testnet") return "testnet";
    if(network === "Movement Devnet") return "Movement Devnet";

    return "mainnet";
}

export function getNetworkURL() {
    const network = getNetwork();
    if(network === "Mainnet") return MAINNET_NODE_URL;
    if(network === "Mainnet (old)") return MAINNET_NODE_URL;
    if(network === "Testnet") return TESTNET_NODE_URL;
    if(network === "Movement Devnet") return MOVEMENT_DEVNET_NODE_URL;
    return MAINNET_NODE_URL;
}

export function getSwapAddress() {
    const network = getNetwork();
    if(network === "Mainnet") return SWAP_ADDRESS_MAINNET;
    if(network === "Mainnet (old)") return SWAP_ADDRESS_MAINNET_OLD;
    if(network === "Testnet") return SWAP_ADDRESS_TESTNET;
    if(network === "Movement Devnet") return SWAP_ADDRESS_MOVEMENT_DEVNET;
    return SWAP_ADDRESS_MAINNET;
}

export function getTokenDeployerAddress() {
    const network = getNetwork();
    if(network === "Mainnet") return TOKEN_DEPLOYER_ADDRESS_MAINNET;
    if(network === "Mainnet (old)") return "";
    if(network === "Testnet") return TOKEN_DEPLOYER_ADDRESS_TESTNET;
    if(network === "Movement Devnet") return TOKEN_DEPLOYER_ADDRESS_MOVEMENT_DEVNET;

    return TOKEN_DEPLOYER_ADDRESS_MAINNET;
}

export function getIndexerGraphql() {
    const network = getNetworkAptos();
    if(network === "mainnet") return MAINNET_INDEXER_GRAPHQL;
    if(network === "testnet") return TESTNET_INDEXER_GRAPHQL;
    if(network === "Movement Devnet") return MOVEMENT_DEVNET_INDEXER_GRAPHQL;

    return MAINNET_INDEXER_GRAPHQL;
}

export function getSmartContractFunction(functionName: string) {
    return smartContractFunctions[getNetworkLowerCase()][functionName];
}

export function getTokenDeployerFunction(functionName: string) {
    return tokenDeployerFunctions[getNetworkAptos()][functionName];
}

export function isValidPairMetadata(metadata: any) {
    if(!metadata || metadata?.balance_y == "0" || metadata?.balance_x == "0") return false;
    return true;
}

export function numFormat(num: number, decimal = 2) {
    return new Intl.NumberFormat("en", {maximumFractionDigits: decimal}).format(num);
}

export function toFixedDown(num: number, precision: number) {
    let factor = Math.pow(10, precision);
    return Math.floor(num * factor) / factor;
}