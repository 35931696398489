// eslint-disable-next-line no-restricted-imports
import { Trans, t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { BrowserEvent, InterfaceElementName, InterfaceEventName, InterfaceSectionName } from '@uniswap/analytics-events'
import { useWeb3React } from '@web3-react/core'
import { sendAnalyticsEvent, Trace, TraceEvent, useTrace } from 'analytics'
import clsx from 'clsx'
import { Search } from 'components/Icons/Search'
import { useCollectionSearch } from 'graphql/data/nft/CollectionSearch'
import { useSearchTokens } from 'graphql/data/SearchTokens'
import useDebounce from 'hooks/useDebounce'
import { useDisableNFTRoutes } from 'hooks/useDisableNFTRoutes'
import { useIsNftPage } from 'hooks/useIsNftPage'
import { useOnClickOutside } from 'hooks/useOnClickOutside'
import { organizeSearchResults } from 'lib/utils/searchBar'
import { Box } from 'nft/components/Box'
import { Column, Row } from 'nft/components/Flex'
import { magicalGradientOnHover } from 'nft/css/common.css'
import { useIsMobile, useIsTablet } from 'nft/hooks'
import { useIsNavSearchInputVisible } from 'nft/hooks/useIsNavSearchInputVisible'
import { ChangeEvent, useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ApolloError, useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import styled from 'styled-components'
import { subheadSmall } from 'nft/css/common.css'
import { ChevronLeftIcon, NavMagnifyingGlassIcon } from '../../nft/components/icons'
import { NavIcon } from './NavIcon'
import * as styles from './SearchBar.css'
import { SearchBarDropdown } from './SearchBarDropdown'
import {useTokenSearch} from "../../hooks/useTokenSearch";
import {MOVEMENT_TOKEN_LIST, TOKEN_LIST, addTokenToStorage, getMergedTokenList} from "../../constants/tokenList";
import { selectCurrency } from 'state/swapBapt/actions'
import { useDispatch } from 'react-redux'
import { Field } from 'state/swapBapt/reducer'
import {useLastBlock} from "../../hooks/useLastBlock";
import {useWallet} from "@aptos-labs/wallet-adapter-react";
import {getAccountCoinValue, getTokenImgUrl} from "../../apiRequests";
import {formatBalance, getBlockchainName, numFormat} from "../../utils/sundry";
import {useSearchTokensBalance} from "../../hooks/useSearchTokensBalance";
import { getNetwork } from '../../utils/sundry'
import {useSearchTokenList} from "../../hooks/useSearchTokenList";

type TokenType = {
  address: string;
  name: string;
  symbol: string;
  iconSrc: string;
  decimals: number;
};

const KeyShortCut = styled.div`
  background-color: ${({ theme }) => theme.surface3};
  color: ${({ theme }) => theme.neutral2};
  padding: 0px 8px;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 535;
  line-height: 16px;
  display: flex;
  align-items: center;
  opacity: 0.6;
  backdrop-filter: blur(60px);
`
const query = gql`
query FindCoin($name: String) {
  coin_infos(where: {name: {_like: $name}}, limit: 10) {
    coin_type
    decimals
    name
    symbol
  }
}
`

const tokenQuery = gql`
query FindCoin($coin_type: String) {
  coin_infos(where: {coin_type: {_like: $coin_type}}, limit: 10) {
    coin_type
    decimals
    name
    symbol
  }
}
`

interface Token {
    coin_type: string
    decimals: number
    name: string
    symbol: string
    iconSrc?: string;
    address?: string;
}


export const SearchBar = () => {
  const [isOpen, toggleOpen] = useReducer((state: boolean) => !state, false);
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchValue = useDebounce(searchValue, 300);
  const searchRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const { pathname } = useLocation();
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const isNavSearchInputVisible = useIsNavSearchInputVisible();
  const shouldDisableNFTRoutes = useDisableNFTRoutes();
  const navigate = useNavigate();

  useOnClickOutside(searchRef, () => {
    isOpen && toggleOpen();
  });

  /*const { data: collections, loading: collectionsAreLoading } = useCollectionSearch(debouncedSearchValue)

  const { chainId } = useWeb3React()
  const { data: tokens, loading: tokensAreLoading } = useSearchTokens(debouncedSearchValue, chainId ?? 1)

  const isNFTPage = useIsNftPage()

  const [reducedTokens, reducedCollections] = organizeSearchResults(isNFTPage, tokens ?? [], collections ?? [])*/

  /*console.log(debouncedSearchValue);*/
  // const { tokens, loading: tokensAreLoading } = useTokenSearch(debouncedSearchValue);

  /*const {
    loading: tokensAreLoading,
    error,
    data: tokens = [],
  } = useQuery(query, {
    variables: { 
      name: `%${searchValue.toUpperCase()}%`,
    },
  });

  const {
    loading: isLoading,
    error: isError,
    data: addressTokens = [],
  } = useQuery(tokenQuery, {
    variables: { 
      "coin_type": `${searchValue}`,
    },
  });*/

  const {tokenList, loading: tokensAreLoading} = useSearchTokenList(searchValue);

  // close dropdown on escape
  useEffect(() => {
    const escapeKeyDownHandler = (event: KeyboardEvent) => {
      if (event.key === "Escape" && isOpen) {
        event.preventDefault();
        toggleOpen();
      }
    };

    document.addEventListener("keydown", escapeKeyDownHandler);

    return () => {
      document.removeEventListener("keydown", escapeKeyDownHandler);
    };
  }, [isOpen, toggleOpen]);

  const dispatch = useDispatch()

  const selectTokenHandler = useCallback(
    (token: Token) => {
      toggleOpen();

      addTokenToStorage(token);

      if(getBlockchainName() === "Aptos") {
        window.location.href = `/#/tokens/${token?.coin_type || token?.address}`;
      }else {
        window.location.reload();
      }

      /*navigate(`/tokens/${token?.coin_type || token?.address}`);*/
      
      /*if(!searchValue) {
        window.location.href = `/#/tokens/${token?.coin_type || token?.address}`
        location.reload();
        navigate(0);
        return;
      }
      addTokenToStorage(token);
      dispatch(selectCurrency({ field: Field.INPUT, currencyId: token.coin_type }));

      window.location.href = `/#/tokens/${token?.coin_type || token?.address}`;
      location.reload();
      navigate(0);*/
    },
    [toggleOpen, searchValue]
  );

  // clear searchbar when changing pages
  useEffect(() => {
    setSearchValue("");
  }, [pathname]);

  // auto set cursor when searchbar is opened
  useEffect(() => {
    if (isOpen) {
      inputRef.current?.focus();
    }
  }, [isOpen]);

  const isMobileOrTablet = isMobile || isTablet || !isNavSearchInputVisible;

  const trace = useTrace({ section: InterfaceSectionName.NAVBAR_SEARCH });

  const navbarSearchEventProperties = {
    navbar_search_input_text: debouncedSearchValue,
    hasInput: debouncedSearchValue && debouncedSearchValue.length > 0,
    ...trace,
  };

  const { i18n } = useLingui(); // subscribe to locale changes
  const placeholderText = isMobileOrTablet
    ? t(i18n)`Search`
    : shouldDisableNFTRoutes
    ? t(i18n)`Search tokens`
    : t(i18n)`Search tokens and pools`;

  const handleKeyPress = useCallback(
    (event: any) => {
      if (event.key === "/") {
        event.preventDefault();
        !isOpen && toggleOpen();
      }
    },
    [isOpen]
  );

  useEffect(() => {
    const innerRef = inputRef.current;

    if (innerRef !== null) {
      //only mount the listener when input available as ref
      document.addEventListener("keydown", handleKeyPress);
    }

    return () => {
      if (innerRef !== null) {
        document.removeEventListener("keydown", handleKeyPress);
      }
    };
  }, [handleKeyPress, inputRef]);

  /*const additiveTokens = {
    coin_infos: !searchValue ? TOKEN_LIST : [...tokens?.coin_infos || [], ...addressTokens?.coin_infos || []]
  }*/

  const additiveTokens = useMemo(() => {
    const blockchain = getBlockchainName();

    if(!searchValue) return blockchain === "Aptos" ? TOKEN_LIST : MOVEMENT_TOKEN_LIST;
    else return tokenList;

  }, [searchValue, tokenList]);

  /*const additiveTokens = useMemo(() => {
    return TOKEN_LIST;
  }, []);

  const tokensAreLoading = false;*/

  const {
    account,
    connected,
  } = useWallet();

  const {tokens: tokensBalance, loading: loadingTokensBalance} = useSearchTokensBalance(account?.address, additiveTokens);

  const sortTokens = useMemo(() => {
    if(!tokensBalance || tokensBalance.length === 0) return [];
    return tokensBalance.slice().sort((a, b) => Number.isNaN(b?.balance - a?.balance) ? 0 : (b?.balance - a?.balance));
  }, [tokensBalance]);

  return (
    <Trace section={InterfaceSectionName.NAVBAR_SEARCH}>
      <Column
        data-cy="search-bar"
        position={{ sm: "fixed", md: "absolute" }}
        width={{ sm: isOpen ? "viewWidth" : "auto", md: "auto" }}
        ref={searchRef}
        className={clsx(styles.searchBarContainerNft, {
          searchBarContainerDisableBlur: isNavSearchInputVisible,
        })}
        display={{ sm: isOpen ? "flex" : "none", xl: "flex" }}
        {...(isNavSearchInputVisible && {
          position: "relative",
          display: "flex",
        })}
        {...(isOpen && {
          boxShadow: "deep",
        })}
      >
        <Row
          className={clsx(
            styles.nftSearchBar,
            !isOpen && !isMobile && magicalGradientOnHover,
            isMobileOrTablet && (isOpen ? styles.visible : styles.hidden)
          )}
          borderRadius={isOpen || isMobileOrTablet ? undefined : "16"}
          borderTopRightRadius={isOpen && !isMobile ? "16" : undefined}
          borderTopLeftRadius={isOpen && !isMobile ? "16" : undefined}
          borderBottomWidth={isOpen || isMobileOrTablet ? "0px" : "1px"}
          backgroundColor={isOpen ? "surface1" : "surface1"}
          onClick={() => !isOpen && toggleOpen()}
          gap="12"
        >
          <Box className={styles.searchContentLeftAlign}>
            <Box display={{ sm: "none", md: "flex" }}>
              <Search width="20px" height="20px" />
            </Box>
            <Box display={{ sm: "flex", md: "none" }} color="neutral3" onClick={toggleOpen}>
              <ChevronLeftIcon />
            </Box>
          </Box>
          <TraceEvent
            events={[BrowserEvent.onFocus]}
            name={InterfaceEventName.NAVBAR_SEARCH_SELECTED}
            element={InterfaceElementName.NAVBAR_SEARCH_INPUT}
            properties={{ ...trace }}
          >
            <Box
              as="input"
              data-cy="search-bar-input"
              placeholder={placeholderText}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                !isOpen && toggleOpen();
                setSearchValue(event.target.value);
              }}
              onBlur={() =>
                sendAnalyticsEvent(
                  InterfaceEventName.NAVBAR_SEARCH_EXITED,
                  navbarSearchEventProperties
                )
              }
              className={`${styles.searchBarInput} ${styles.searchContentLeftAlign}`}
              value={searchValue}
              ref={inputRef}
              width="full"
            />
          </TraceEvent>
          {!isOpen && <KeyShortCut>/</KeyShortCut>}
        </Row>
        <Column overflow="hidden" className={clsx(isOpen ? styles.visible : styles.hidden)}>
          {isOpen && (
            // <SearchBarDropdown
            //   toggleOpen={toggleOpen}
            //   tokens={tokens}
            //   collections={[]}
            //   queryText={debouncedSearchValue}
            //   hasInput={debouncedSearchValue.length > 0}
            //   isLoading={tokensAreLoading}
            // />
            <Column
              overflow="hidden"
              className={clsx(styles.searchBarDropdownNft, styles.searchBarScrollable)}
            >
              <Box opacity={tokensAreLoading ? "0.3" : "1"} transition="125">
                <Row
                  paddingX="16"
                  paddingY="4"
                  gap="8"
                  color="neutral2"
                  className={subheadSmall}
                  style={{ lineHeight: "20px" }}
                >
                  <Box>
                    <Trans>Tokens</Trans>
                  </Box>
                </Row>
                {sortTokens.length !== 0 || loadingTokensBalance ? (
                    !loadingTokensBalance ? (
                        sortTokens.map((token: any, index: number) => <SearchRow token={token} selectTokenHandler={selectTokenHandler} key={index}/>)
                  ) : (
                    <Row className={styles.suggestionRow}>
                      <Box>
                        <>Loading...</>
                      </Box>
                    </Row>
                  )
                ) : (
                  <Box className={styles.notFoundContainer}>
                    <>No tokens found.</>
                  </Box>
                )}
              </Box>
            </Column>
          )}
        </Column>
      </Column>
      {isMobileOrTablet && (
        <NavIcon onClick={toggleOpen} label={placeholderText}>
          <NavMagnifyingGlassIcon />
        </NavIcon>
      )}
    </Trace>
  );
}

const SearchRow = ({token, selectTokenHandler}: {token: any, selectTokenHandler: any}) => {
  const { coin_type, decimals, name, symbol, iconSrc, address, balance } = token;
  const type = (coin_type || address) || "";
  const shortenHashString = (string: string, divider = [9, 6]) => {
    if(string === "-" || !string) return "-";
    if(string.length < 16 || divider[0] === 0 && divider[1] === 0) return string;
    const fromLast = string.lastIndexOf("::");
    const typeString = fromLast > -1 ? string.slice(fromLast + 2) : null;
    if(typeString && typeString.length < 7) return `${string.slice(0, divider[0])}...${typeString}`

    return `${string.slice(0, divider[0])}...${string.slice(-divider[1])}`;
  };

  const coinValue = useMemo(() => {
    if(!balance || !Number(balance)) return "";
    return numFormat(balance, 6)
  }, [balance]);

  /*const lastBlock = useLastBlock();

  const {
    account,
    connected,
  } = useWallet();

  const [coinValue, setCoinValue] = useState("");

  useEffect(() => {
    if (connected && account) {
      if (type) {
        getAccountCoinValue(account.address, type).then(res => {
          if(res.data) {
            const value = res.data.coin.value;
            setCoinValue(numFormat(formatBalance(value, decimals), 6));
          }else {
            setCoinValue("");
          }
        });
      }
    }
  }, [connected, account, lastBlock]);*/

  const logo = getMergedTokenList().find(x => x.address === type)?.iconSrc || getTokenImgUrl(symbol || "");

  return (
      <Row
          className={styles.suggestionRow}
          key={type}
          onClick={() => selectTokenHandler(token)}
      >
        <Row width="full">
          <Box className={styles.imageHolder}>
            <img
                className={styles.imageHolder}
                src={logo}
                alt={symbol}
            />
          </Box>
          <Column gap="4" width="full">
            <Row justifyContent="space-between">
              <Box className={styles.primaryText}>{name}</Box>
              {coinValue && <Box className={styles.primaryText}>{coinValue} {symbol}</Box>}
            </Row>

            <Row justifyContent="space-between">
              <Box className={styles.secondaryText}>{symbol}</Box>
              <Box className={styles.secondaryText}>{shortenHashString(type)}</Box>
              {/* <Box
                                  borderRadius="round"
                                  height="16"
                                  width="48"
                                  background="surface2"
                                /> */}
            </Row>
          </Column>
        </Row>
      </Row>
  );
}
