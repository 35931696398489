import {denominator, MAINNET_INDEXER_GRAPHQL, SWAP_ADDRESS_MAINNET} from "constants/aptos";
import {
    getIndexerGraphql,
    getNetworkAptos,
    getNetworkId,
    getNetworkURL,
    getSmartContractFunction,
    getSwapAddress, getTokenDeployerAddress, getTokenDeployerFunction
} from "./utils/sundry";

export const apiAptools = "https://api.aptools.io";
export const apiBaptswap = "https://baptapi.aptools.io";

const initAptoolsApi = {
    method: "GET",
    headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer iiskLM6WrAI@lkwQwIWuD*RWIQOhqdpkiisk*M6WrAIAlk@QwIWuDTRWIQOhqdpk"
    },
};

const initAptosApi = {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
    },
};

export function getPriceApt() {
    return fetch(`https://api.coingecko.com/api/v3/simple/price?ids=aptos&vs_currencies=usd`).then((res) => res.json());
}

export function getStakingPools() {
    return fetch(`${apiBaptswap}/baptswap_${getNetworkId()}/api/v1/staking_pools`, { method: "GET" }).then((res) => res.json());
}


export function getSingleTokenStats(token: string | undefined) {
    return fetch(`${apiBaptswap}/baptswap_${getNetworkId()}/api/v1/coin_info/${token}`, { method: "GET" }).then((res) => res.json());
}

export function getMainnetChartData(token: string | undefined, minutes: number, limit: number ) {
    /*return fetch(
        `https://gq.baptswap.com/v1/graphql`, 
        { 
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            },
            body: JSON.stringify({
                query: `query SwapTransactions($token: String, $minutes: Int, $limit: Int) {
                    tokenChart(token: $token, minutes: $minutes, limit: $limit) {
                      date
                      coin
                      price
                      volume
                    }
                  }`,
                variables: {
                    token,
                    minutes,
                    limit: limit / minutes
                }
            }),
        }
    ).then((res) => res.json());*/

    return fetch(`${apiBaptswap}/baptswap_${getNetworkId()}/api/v1/coin_chart/${token}?interval_minutes=${minutes}&records_amount=${limit}`, { method: "GET" }).then((res) => res.json());
}

export function getMainnetInfoData(token: string | undefined) {
    return fetch(
        `https://gq.baptswap.com/v1/graphql`, 
        { 
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            },
            body: JSON.stringify({
                query: `query SwapTransactions($token: String) {
                    tokenInfo(token: $token) {
                      description
                      tvl
                      volume
                    }
                  }`,
                variables: {
                    token
                }
            }),
        }
    ).then((res) => res.json());
}

export function getTokenInfoIndexer(token: string | undefined, indexer?: string) {
    return fetch(
        indexer || getIndexerGraphql(),
        {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            },
            body: JSON.stringify({
                query: `query CoinInfos ($coin_type: String) {
  coin_infos(
    where: {coin_type: {_eq: $coin_type}}
  ) {
    decimals
    name
    symbol
    creator_address
    coin_type
  }
}
`,
                variables: {
                    "coin_type": token
                }
            }),
        }
    ).then((res) => res.json());
}

export function getTokensByName(tokenName: string | undefined) {
    return fetch(
        MAINNET_INDEXER_GRAPHQL,
        {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            },
            body: JSON.stringify({
                query: `query CoinInfos ($name: String) {
  coin_infos(where: {name: {_like: $name}}, limit: 10) {
    decimals
    name
    symbol
    creator_address
    coin_type
  }
}
`,
                variables: {
                    "name": tokenName
                }
            }),
        }
    ).then((res) => res.json());
}

export async function getSearchTokenListAptos(search: string) {
    const tokens1 = await getTokensByName(`%${search.toUpperCase()}%`);
    const tokens2 = await getTokenInfoIndexer(search, MAINNET_INDEXER_GRAPHQL);
    return [...tokens1?.data?.coin_infos || [], ...tokens2?.data?.coin_infos || []];
}

export function getTokenInfo(token: string) {
    return fetch(`https://api.aptools.io/analytics/v1/token_rate?token=${token}`, initAptoolsApi).then((res) => res.json());
}

export function getTokenListInfo() {
    return fetch(`https://api.aptools.io/analytics/v1/token_info_list`, initAptoolsApi).then((res) => res.json());
}

export function getTokensStatistics() {
    return fetch(`${apiBaptswap}/baptswap_${getNetworkId()}/api/v1/top_coins`, { method: "GET" }).then((res) => res.json());
}

export function getMyActivePairs(account: string) {
    return fetch(`${apiBaptswap}/baptswap_${getNetworkId()}/api/v1/my_active_pairs/${account}`, { method: "GET" }).then((res) => res.json());
}

export function getCoinInfoMovement(coin: string) {
    return fetch(`https://baptapi.aptools.io/baptswap_testnet/api/v1/movement/coin_info/${coin}`, { method: "GET" }).then((res) => res.json());
}

export function buildPublishPayload(body: any) {
    const url = getNetworkId() === "Movement Devnet" ? `https://baptapi.aptools.io/baptswap_testnet/api/v1/movement_build_publish_payload` : `${apiBaptswap}/baptswap_${getNetworkId()}/api/v1/build_publish_payload`;

    return fetch(url, {
        body: new URLSearchParams(body), method: "POST",
        headers:{
            'Content-Type': 'application/x-www-form-urlencoded'
        }, }).then((res) => res.json());
}

export function generateCoinAndPair(body: any) {
    return fetch(`${apiBaptswap}/baptswap_${getNetworkId()}/api/v1/generate_coin_and_pair`, {
        body: new URLSearchParams(body), method: "POST",
        headers:{
            'Content-Type': 'application/x-www-form-urlencoded'
        }, }).then((res) => res.json());
}

export function generateCoinAndLiquidate(body: any) {
    return fetch(`${apiBaptswap}/baptswap_${getNetworkId()}/api/v1/generate_coin_and_liquidate`, {
        body: new URLSearchParams(body), method: "POST",
        headers:{
            'Content-Type': 'application/x-www-form-urlencoded'
        }, }).then((res) => res.json());
}

export function getAccountTokens(account: string) {
    const body = {
        account,
        currentPage: 1,
        order: "desc",
        orderBy: "balance",
        pageSize: 10,
    };
    return fetch(`https://api.aptools.io/analytics/v1/account_coins`, {body: JSON.stringify(body), ...initAptoolsApi, method: "POST"}).then((res) => res.json());
}

export function getTokenImgUrl(name: string = "") {
    return apiAptools + `/images/${name.toLowerCase().replace(/ /g, '-')}.png`;
}

export function getTokenPairMetadata(inputToken: string, outputToken: string) {
    /*const aptosClient = new AptosClient(TESTNET_NODE_URL, {
        WITH_CREDENTIALS: false,
    });*/
    const tokenPairResource = `${getSwapAddress()}::${getSmartContractFunction("swapSection")}::TokenPairMetadata<${inputToken},${outputToken}>`;
    return fetch(`${getNetworkURL()}/accounts/${getSwapAddress()}/resource/${tokenPairResource}`).then((res) => res.json());
    /*return aptosClient.getAccountResource(SWAP_ADDRESS, tokenPairResource);*/
}

export function getLedgerInfo() {
    return fetch(`${getNetworkURL()}`).then((res) => res.json());
}

export function getAccountCoinValue(account: string, coin: string) {
    return fetch(`${getNetworkURL()}/accounts/${account}/resource/0x1::coin::CoinStore<${coin}>`).then((res) => res.json());
}

export function getPoolInfo(inputToken: string, outputToken: string) {
    return fetch(`${getNetworkURL()}/accounts/${getSwapAddress()}/resource/${getSwapAddress()}::${getSmartContractFunction("stakeSection")}::TokenPairRewardsPool<${inputToken}, ${outputToken}>`).then((res) => res.json());
}

export function getRewardsPoolUserInfo(account: string, inputToken: string, outputToken: string) {
    return fetch(`${getNetworkURL()}/accounts/${account}/resource/${getSwapAddress()}::${getSmartContractFunction("stakeSection")}::RewardsPoolUserInfo<${inputToken}, ${outputToken}, ${inputToken}>`).then((res) => res.json());
}

export function getTokenOwner(token: string) {
    const body = {
        "function": getSmartContractFunction("get_owner"),
        "type_arguments": [token],
        "arguments": []
    };
    return fetch(`${getNetworkURL()}/view`, {body: JSON.stringify(body), ...initAptosApi}).then((res) => res.json());
}

export function getTokenInfoFromSmartContract(token: string) {
    const body = {
        "function": getSmartContractFunction("get_info"),
        "type_arguments": [token],
        "arguments": []
    };
    return fetch(`${getNetworkURL()}/view`, {body: JSON.stringify(body), ...initAptosApi}).then((res) => res.json());
}

export function getDexLiquidityFee() {
    const body = {
        "function": getSmartContractFunction("get_dex_liquidity_fee"),
        "type_arguments": [],
        "arguments": []
    };
    return fetch(`${getNetworkURL()}/view`, {body: JSON.stringify(body), ...initAptosApi}).then((res) => res.json());
}

export function getDexTreasuryFee() {
    const body = {
        "function": getSmartContractFunction("get_dex_treasury_fee"),
        "type_arguments": [],
        "arguments": []
    };
    return fetch(`${getNetworkURL()}/view`, {body: JSON.stringify(body), ...initAptosApi}).then((res) => res.json());
}

export async function getProtocolFee() {
    const body = {
        "function": getSmartContractFunction("get_dex_fees"),
        "type_arguments": [],
        "arguments": []
    };
    const fee = await fetch(`${getNetworkURL()}/view`, {body: JSON.stringify(body), ...initAptosApi}).then((res) => res.json());
    return Number(fee[0]) / denominator;
    /*const dexLiquidityFee = await getDexLiquidityFee();
    const dexTreasuryFee = await getDexTreasuryFee();
    return (Number(dexLiquidityFee[0]) + Number(dexTreasuryFee[0])) / denominator;*/
}

export async function getTokenFee(token: string) {
    const body = {
        "function": getSmartContractFunction("get_all_fee_on_transfer"),
        "type_arguments": [token],
        "arguments": []
    };
    const fee = await fetch(`${getNetworkURL()}/view`, {body: JSON.stringify(body), ...initAptosApi}).then((res) => res.json());
    return Number(fee[0]) / denominator;
    /*const dexLiquidityFee = await getDexLiquidityFee();
    const dexTreasuryFee = await getDexTreasuryFee();
    return (Number(dexLiquidityFee[0]) + Number(dexTreasuryFee[0])) / denominator;*/
}

export function getFeeTokenDeployer() {
    const body = {
        "function": `${getTokenDeployerAddress()}::deployer::get_fee`,
        "type_arguments": [],
        "arguments": []
    };
    return fetch(`${getNetworkURL()}/view`, {body: JSON.stringify(body), ...initAptosApi}).then((res) => res.json());
}

export function getAccountResources(account: string, limit: number = 20) {
    return fetch(`${getNetworkURL()}/accounts/${account}/resources?limit=${limit}`).then((res) => res.json());
}

export function getAccountTransactions(account: string, limit: number = 20) {
    return fetch(`${getNetworkURL()}/accounts/${account}/transactions?limit=${limit}`).then((res) => res.json());
}


export async function getAccountStake(account: string) {
    const resources = await getAccountResources(account, 500);
    const rewardsPoolUserInfo = resources.filter((resource: any) => resource.type.includes(`${getSwapAddress()}::swap_v2::RewardsPoolUserInfo`));
    return rewardsPoolUserInfo;
}

export function getLPTokenInfo(account: string, inputToken: string, outputToken: string) {
    return fetch(`${getNetworkURL()}/accounts/${account}/resource/0x1::coin::CoinInfo<${getSwapAddress()}::${getSmartContractFunction("swapSection")}::LPToken<${inputToken}, ${outputToken}>>`).then((res) => res.json());
}

export function getAccountLPToken(account: string, inputToken: string, outputToken: string) {
    return fetch(`${getNetworkURL()}/accounts/${account}/resource/0x1::coin::CoinStore<${getSwapAddress()}::${getSmartContractFunction("swapSection")}::LPToken<${inputToken}, ${outputToken}>>`).then((res) => res.json());
}

export async function getSharePoolPercentage(account: string, inputToken: string, outputToken: string) {
    const totalLPToken = await getLPTokenInfo(getSwapAddress(), inputToken, outputToken);
    const totalLPTokenValue = totalLPToken.data.supply.vec[0].integer.vec[0].value;

    const accountLPToken = await getAccountLPToken(account, inputToken, outputToken);
    const accountLPTokenValue = accountLPToken.data.coin.value;

    const sharePoolPercentage = (accountLPTokenValue / totalLPTokenValue) * 100;
    return sharePoolPercentage;
}

export async function getAccountLPTokens(account: string) {
    const resources = await getAccountResources(account, 500);
    const tokens = resources.filter((resource: any) => resource.type.includes(`${getSwapAddress()}::${getSmartContractFunction("swapSection")}::LPToken`));
    return tokens;
}