import {Separator, ThemedText} from "../../theme/components";
import {Trans} from "@lingui/macro";
import {TraceEvent} from "../../analytics";
import {formatCommonPropertiesForTrade} from "../../lib/utils/analytics";
import {RowBetween, RowFixed} from "../../components/Row";
import {LoadingOpacityContainer} from "../../components/Loader/styled";
import TradePrice from "../../components/swap/TradePrice";
import {isSubmittableTrade} from "../../state/routing/utils";
import GasEstimateTooltip from "../../components/swap/GasEstimateTooltip";
import {useFormatter} from "../../utils/formatNumbers";
import AnimatedDropdown from "../../components/AnimatedDropdown";
import {ButtonEmphasis, ButtonSize, ThemeButton} from "../../components/Button";
import styled, {useTheme} from "styled-components";
import {ChevronDown} from "react-feather";
import Column from "../../components/Column";
import {Link as NativeLink} from "react-router-dom";
import {
    BrowserEvent,
    InterfaceElementName,
    InterfaceEventName,
    InterfacePageName,
    InterfaceSectionName,
    SharedEventName,
    SwapEventName,
} from '@uniswap/analytics-events'
import SwapLineItem from "./SwapLineItem";
import {useEffect, useMemo, useState} from "react";
import {TokenPairMetadataType} from "./index";
import {
    calculatePriceImpact,
    formatBalance,
    getNetworkURL,
    getSwapAddress,
    isValidPairMetadata,
    numberWithCommas
} from "utils/sundry";
import {TOKEN_LIST, getMergedTokenList} from "../../constants/tokenList";
import {getAccountCoinValue, getPriceApt, getProtocolFee, getTokenFee, getTokenInfo} from "../../apiRequests";
import {useWallet} from "@aptos-labs/wallet-adapter-react";
import {AptosAccount, AptosClient, BCS, HexString, TxnBuilderTypes, Types} from "aptos";
import { useUserSlippageTolerance, useUserSlippageToleranceWithDefault } from "state/user/hooks";
import { SlippageTolerance } from "state/user/types";
import {useLastBlock} from "../../hooks/useLastBlock";


const StyledHeaderRow = styled(RowBetween)<{ disabled: boolean; open: boolean }>`
  padding: 0;
  align-items: center;
  /*cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};*/
`

const RotatingArrow = styled(ChevronDown)<{ open?: boolean }>`
  transform: ${({ open }) => (open ? 'rotate(180deg)' : 'none')};
  transition: transform 0.1s linear;
`

const SwapDetailsWrapper = styled(Column)`
  padding-top: ${({ theme }) => theme.grids.md};
`

const Wrapper = styled(Column)`
  border: 1px solid ${({ theme }) => theme.surface3};
  border-radius: 16px;
  padding: 12px 16px;
`
const Link = styled(NativeLink)`
  text-decoration: none;
`

interface SwapDetailsProps {
    inputAmount: string
    inputToken: number
    outputAmount: string
    outputToken: number
    tokenPairMetadata: TokenPairMetadataType | undefined
    isLastEditInput: boolean
    setPriceImpact: (impact: string) => void
    networkCost: number
    setNetworkCost: (networkCost: number) => void
}   

export default function SwapDetails({tokenPairMetadata, inputAmount, inputToken, outputAmount, outputToken, isLastEditInput, setPriceImpact, networkCost, setNetworkCost}: SwapDetailsProps) {
    const [showDetails, setShowDetails] = useState(false);
    const theme = useTheme();
    const lastBlock = useLastBlock();

    const {
        account,
        connected,
    } = useWallet();

    const aptosClient = new AptosClient(getNetworkURL(), {
        WITH_CREDENTIALS: false,
    });

    const [protocolFee, setProtocolFee] = useState(0);
    const [networkCostUSD, setNetworkCostUSD] = useState("0");

    useEffect(() => {
        getPriceApt().then(res => {
            const price = res.aptos.usd;
            setNetworkCostUSD((networkCost * price).toFixed(5));
        });
    }, [networkCost]);

    const [inputFee, setInputFee] = useState(0);
    const [outputFee, setOutputFee] = useState(0);

    const simulateTransaction = async () => {
        if(!account) return;
        const payload = new TxnBuilderTypes.TransactionPayloadEntryFunction(
            new TxnBuilderTypes.EntryFunction(
                TxnBuilderTypes.ModuleId.fromStr(`${getSwapAddress()}::router_v2`),
                new TxnBuilderTypes.Identifier("swap_exact_input"),
                [new TxnBuilderTypes.TypeTagStruct(TxnBuilderTypes.StructTag.fromString(getMergedTokenList()[inputToken].address)),
                    new TxnBuilderTypes.TypeTagStruct(TxnBuilderTypes.StructTag.fromString(getMergedTokenList()[outputToken].address))],
                [BCS.bcsSerializeUint64(Number((Number(inputAmount || 0) * 10 ** getMergedTokenList()[inputToken].decimals).toFixed(0))),
                    BCS.bcsSerializeUint64(Number((Number(outputAmount || 0) * 0.1 * 10 ** getMergedTokenList()[outputToken].decimals).toFixed(0)))]
            ),
        );
        const accountAddress = new HexString(account?.address || "");
        const raw = await aptosClient.generateRawTransaction(accountAddress, payload);
        // @ts-ignore
        const transaction = await aptosClient.simulateTransaction(new TxnBuilderTypes.Ed25519PublicKey(HexString.ensure(account.publicKey || "").toUint8Array()), raw);
        const gasUsed = Number(transaction[0].gas_used);

        setNetworkCost(gasUsed / (10 ** 5));
        /*const priceApt = await getPriceApt()
        const price = priceApt.aptos.usd;
        setNetworkCost(((gasUsed / (10 ** 6)) * price).toFixed(5));*/
    };

    useEffect(() => {
        if(connected && account) simulateTransaction();
    }, [isLastEditInput, inputToken, outputToken, outputAmount, inputAmount, account, connected, lastBlock]);

    useEffect(() => {
        getProtocolFee().then(res => {
           setProtocolFee(res);
        });

        /*getTokenInfo("0x1::aptos_coin::AptosCoin").then(res => {
            console.log(res);
        });*/
    }, []);

    useEffect(() => {
        if (inputToken === -1) return;
        //console.log("input token:" + inputToken + " ---- " +getMergedTokenList()[inputToken] );
        getTokenFee(getMergedTokenList()[inputToken].address).then(res => {
            if(Number(res)) setInputFee(parseFloat((res * 100).toFixed(2)));
            else setInputFee(0);
        })
    }, [inputToken]);

    useEffect(() => {
        if (outputToken === -1) return;
        getTokenFee(getMergedTokenList()[outputToken].address).then(res => {
            if(Number(res)) setOutputFee(parseFloat((res * 100).toFixed(2)));
            else setOutputFee(0);
        });
    }, [outputToken]);

    const totalTax = useMemo(() => {
        let totalFee = 0;
        if (tokenPairMetadata) {
            totalFee =
                Number(tokenPairMetadata.liquidity_fee) +
                Number(tokenPairMetadata.rewards_fee) +
                Number(tokenPairMetadata.team_fee);
        }
        return totalFee;
    }, [tokenPairMetadata]);

    const inputTokenReserves = useMemo(() => {
        if(!tokenPairMetadata) return 0;
        let value = numberWithCommas(formatBalance(Number(tokenPairMetadata.balance_x), getMergedTokenList()?.[inputToken]?.decimals), getMergedTokenList()[inputToken].decimals);
        return value;
    }, [inputToken, tokenPairMetadata]);

    const outputTokenReserves = useMemo(() => {
        if(!tokenPairMetadata) return 0;
        let value = numberWithCommas(formatBalance(Number(tokenPairMetadata.balance_y), getMergedTokenList()?.[outputToken]?.decimals), getMergedTokenList()[outputToken].decimals);
        return value;
    }, [outputToken, tokenPairMetadata]);

    const priceImpact = useMemo(() => {
        if(!isValidPairMetadata(tokenPairMetadata) || !Number(inputAmount)) {
            if(setPriceImpact) setPriceImpact(null);
            return "0";
        }
        const inputBalance = formatBalance(Number(tokenPairMetadata.balance_x), getMergedTokenList()?.[inputToken]?.decimals);
        const outputBalance = formatBalance(Number(tokenPairMetadata.balance_y), getMergedTokenList()?.[outputToken]?.decimals);
        const value = calculatePriceImpact(inputBalance, outputBalance, Number(inputAmount));
        if(setPriceImpact) setPriceImpact(value);
        return value;
    }, [inputToken, tokenPairMetadata, outputToken, inputAmount]);

    const fee = useMemo(() => {
        let value = (Number(outputAmount) * protocolFee).toLocaleString(undefined, {maximumSignificantDigits: 8});
        return value;
    }, [outputAmount, protocolFee]);

    const feePercent = useMemo(() => {
        let value = parseFloat((protocolFee * 100).toFixed(2));
        return value;
    }, [protocolFee]);

    const taxPercent = useMemo(() => totalTax/100, [totalTax]);

    const taxValue = useMemo(() => {
        let value = ((Number(inputAmount) * totalTax) / 10000).toLocaleString(undefined, {maximumSignificantDigits: 8,});
        return value;
    }, [inputAmount]);

    const baptValue = useMemo(() => {
        if(!isValidPairMetadata(tokenPairMetadata)) return "0";
        const inputBalance = formatBalance(Number(tokenPairMetadata.balance_x), getMergedTokenList()[inputToken].decimals);
        const outputBalance = formatBalance(Number(tokenPairMetadata.balance_y), getMergedTokenList()[outputToken].decimals);
        return (inputBalance / outputBalance).toFixed(8)
    }, [inputToken, outputToken, tokenPairMetadata])

    const receive = useMemo(() => {
        const digits = getMergedTokenList()?.[outputToken]?.decimals || 1;
        let value = isLastEditInput
            ? (
                Number(outputAmount) -
                (Number(outputAmount) *
                    (totalTax + 90)) /
                10000
            ).toLocaleString(undefined, {
                maximumSignificantDigits:
                digits,
            })
            : Number(outputAmount).toLocaleString(
                undefined,
                {
                    maximumSignificantDigits:
                    digits,
                }
            );
        return value;
    }, [outputAmount, totalTax, outputToken, isLastEditInput]);

    if(!inputAmount) return <></>;

    return (
        <Wrapper>
            <TraceEvent
                events={[BrowserEvent.onClick]}
                name={SwapEventName.SWAP_DETAILS_EXPANDED}
                element={InterfaceElementName.SWAP_DETAILS_DROPDOWN}
            >
                <StyledHeaderRow
                    data-testid="swap-details-header-row"
                    onClick={() => setShowDetails(!showDetails)}
                    disabled={false}
                    open={showDetails}
                >
                    <RowFixed>
                        {/*{trade ? (
                            <LoadingOpacityContainer $loading={syncing} data-testid="trade-price-container">
                                <TradePrice price={trade.executionPrice} />
                            </LoadingOpacityContainer>
                        ) : loading || syncing ? (
                            <ThemedText.DeprecatedMain fontSize={14}>
                                <Trans>Fetching best price...</Trans>
                            </ThemedText.DeprecatedMain>
                        ) : null}*/}
                        {inputAmount && <div>{1 + " " + getMergedTokenList()[outputToken].symbol} = {baptValue + " " + getMergedTokenList()[inputToken].symbol}</div>}
                        {/* <div>{inputTokenReserves + " " + getMergedTokenList()[inputToken].symbol}</div> */}
                    </RowFixed>
                    <RowFixed gap="xs">
                        {inputAmount && <div>{networkCostUSD}$</div>}
                        <RotatingArrow stroke={theme.neutral3} open={Boolean(showDetails)}/>
                        {/*<div><Link to="/token-pair"><ThemeButton size={ButtonSize.medium} emphasis={ButtonEmphasis.highSoft}>View Pair Info</ThemeButton></Link></div>*/}
                        {/* {!showDetails && isSubmittableTrade(undefined) && (
                            <GasEstimateTooltip trade={undefined} loading={false} />
                        )} */}
                        
                    </RowFixed>
                </StyledHeaderRow>
                <StyledHeaderRow data-testid="swap-details-header-row"
                                 onClick={() => setShowDetails(!showDetails)}
                                 disabled={false}
                                 open={showDetails}>
                    <RowFixed>
                        {/* <div>{outputTokenReserves + " " + getMergedTokenList()[outputToken].symbol}</div> */}
                    </RowFixed>
                </StyledHeaderRow>
            </TraceEvent>
            <AdvancedSwapDetails inputFee={inputFee} outputFee={outputFee} networkCost={networkCostUSD} priceImpact={priceImpact} protocolFeePercent={feePercent} open={showDetails} fee={fee} taxPercent={taxPercent} taxValue={taxValue} inputToken={inputToken} receive={receive} outputToken={outputToken} isLastEditInput={isLastEditInput} outputAmount={outputAmount}/>
        </Wrapper>
    )
}

interface AdvancedSwapDetailsProps {
    fee: string
    taxPercent: number
    taxValue: string
    inputToken: number
    receive: string
    outputToken: number
    isLastEditInput: boolean
    open: boolean
    protocolFeePercent: number
    priceImpact: string
    networkCost: string
    inputFee: number
    outputFee: number,
    outputAmount: string;
}

function getSlippageData() {
    const [userSlippageTolerance] = useUserSlippageTolerance();
    const { formatSlippage } = useFormatter()
    if (userSlippageTolerance === SlippageTolerance.Auto) {
        return "Auto";
    }
    return formatSlippage(userSlippageTolerance)
}

function AdvancedSwapDetails({inputFee, outputFee, networkCost, priceImpact, fee, taxPercent, taxValue, inputToken, receive, outputToken, isLastEditInput, open, protocolFeePercent, outputAmount}: AdvancedSwapDetailsProps) {
    const getPriceColor = (price: number) => {
        if(price < 3) return "white";
        if(price < 5 && price >= 3) return "deprecated_accentWarning"
        if(price >= 5) return "critical"
        /* if(price > 15) return "deprecated_accentWarning" */
        return "white";
    }


    const getReceive = () => {
        return (Number(outputAmount) - (Number(receive?.replaceAll(" ", "") || 0) * Number(priceImpact) / 100)).toFixed(2)
    }

    return (
        <AnimatedDropdown open={open}>
            <SwapDetailsWrapper gap="md" data-testid="advanced-swap-details">
                <Separator />
                <SwapLineItem label={"Price Impact"} color={getPriceColor(Number(priceImpact))} value={`${priceImpact}%`}/>
                <SwapLineItem label={"Max. slippage"} value={`${getSlippageData()}`}/>
                <SwapLineItem label={"Token X Fee"} value={`${inputFee}%`}/>
                <SwapLineItem label={"Token Y Fee"} value={`${outputFee}%`}/>
                <SwapLineItem label={`Fee`} value={`${fee} ${getMergedTokenList()[outputToken].symbol}`}/>
                <SwapLineItem label={"Network Cost"} value={`${networkCost}$`} image={"/external_media/aptos-transparent.png"}/>
                {/*<SwapLineItem label={`Tax (${taxPercent}%)`} value={`${taxValue} ${getMergedTokenList()[inputToken].symbol}`}/>*/}
                <Separator />
                {/* <SwapLineItem label={"You will receive"} value={`${isLastEditInput ? "~" : ""}${getReceive()} ${getMergedTokenList()[outputToken].symbol}`}/> */}
            </SwapDetailsWrapper>
        </AnimatedDropdown>
    )
}
