import { useState, useEffect, useCallback, useMemo, useReducer } from 'react'
import {ArrowWrapper, PageWrapper, SwapWrapper} from "../../components/swap/styled";
import {useIsDarkMode} from "../../theme/components/ThemeToggle";
import styled, {useTheme} from "styled-components";
import {useLocation, useNavigate} from "react-router-dom";
import {Trace, TraceEvent} from "../../analytics";
import {NetworkAlert} from "../../components/NetworkAlert/NetworkAlert";
import {SwitchLocaleLink} from "../../components/SwitchLocaleLink";
import SwapHeader from "./SwapHeader";
import SwapCurrencyInputPanel from "./SwapCurrencyInputPanel";
import Column, {AutoColumn} from "../../components/Column";
import {Trans} from "@lingui/macro";
import {ButtonEmphasis, ButtonLight, ButtonSize, ThemeButton} from "../../components/Button";
import {useWallet} from "@aptos-labs/wallet-adapter-react";
import {AptosClient, Types} from "aptos";
import {
    BrowserEvent,
    InterfaceElementName,
    InterfaceEventName,
    InterfacePageName,
    InterfaceSectionName,
    SharedEventName,
    SwapEventName,
} from '@uniswap/analytics-events'
import {ArrowDown} from "react-feather";
import {ArrowContainer} from "../Swap";
import SwapDetails from './SwapDetails';
import { WalletSelector } from "@aptos-labs/wallet-adapter-ant-design";
import {
    calculateRate,
    formatBalance,
    getNetworkURL, getSmartContractFunction,
    isValidPairMetadata, numFormat, toFixedDown
} from 'utils/sundry';
import {getAccountCoinValue, getTokenPairMetadata} from 'apiRequests';
import {TOKEN_LIST, getMergedTokenList} from "../../constants/tokenList";
import {useAccountDrawer} from "../../components/AccountDrawer";
import {Currency, Percent} from "@uniswap/sdk-core";
import PriceImpactModal from 'components/swap/PriceImpactModal';
import { RowBetween, RowFixed } from 'components/Row';
import PriceImpactWarning from 'components/swap/PriceImpactWarning';
import { useSelector } from 'react-redux';
import store from 'state';
import { AppState } from 'state/reducer';
import { Field } from 'state/swapBapt/reducer';
import {PendingModalContent} from "../../components/swap/PendingModalContent";
import {useLastBlock} from "../../hooks/useLastBlock";
import { useUserSlippageTolerance } from 'state/user/hooks';
import {APT_TOKEN_ADDRESS, smartContractFunctions} from "../../constants/aptos";
import {MOBILE_MEDIA_BREAKPOINT, SMALL_MEDIA_BREAKPOINT} from 'components/Tokens/constants';

const SwapBg = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  width: 100vw;
  height: 100vh;
  z-index: -1;
  background: url("/images/ForestBg.webp") no-repeat bottom;
  background-size: contain;
`

const SwapSection = styled.div`
  background-color: ${({ theme }) => theme.surface2};
  border-radius: 16px;
  color: ${({ theme }) => theme.neutral2};
  font-size: 14px;
  font-weight: 500;
  height: 120px;
  line-height: 20px;
  padding: 16px;
  position: relative;

  &:before {
    box-sizing: border-box;
    background-size: 100%;
    border-radius: inherit;

    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
    pointer-events: none;
    content: '';
    border: 1px solid ${({ theme }) => theme.surface2};
  }

  &:hover:before {
    border-color: ${({ theme }) => theme.deprecated_stateOverlayHover};
  }

  &:focus-within:before {
    border-color: ${({ theme }) => theme.deprecated_stateOverlayPressed};
  }

  @media only screen and (max-width: ${SMALL_MEDIA_BREAKPOINT}) {
    height: 135px;
  }
`

const AuditedContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  margin-top: 2rem !important;
  color: ${({ theme }) => theme.neutral2};
  font-size: 14px;
`

const OutputSwapSection = styled(SwapSection)`
  border-bottom: ${({ theme }) => `1px solid ${theme.surface1}`};
`

export type TokenPairMetadataType = {
    balance_x: string;
    balance_y: string;
    liquidity_fee: string;
    rewards_fee: string;
    team_fee: string;
    team_balance_x?: string;
    team_balance_y?: string;
    treasury_balance_x?: string;
    treasury_balance_y?: string;
    treasury_fee?: string;
    owner?: string;
};

export default function SwapPage({ className }: { className?: string }) {

    const location = useLocation()

    return (
        <Trace>
            <PageWrapper>
                <Swap defaultInputTokenIndex={0}/>
                <NetworkAlert />
            </PageWrapper>
            <AuditedContainer>
                <div>Audited by</div>
                <img src="/images/logoMovebit.png" width={150} alt="movebit"/>
            </AuditedContainer>
            {/*{location.pathname === '/swap' && <SwitchLocaleLink />}*/}
        </Trace>
    )
}

const StyledButtonLight = styled(ButtonLight)`
  z-index: 0;
  font-weight: 535;
  border-radius: 16px;
`

const StyledButtonRed = styled(ThemeButton)`
  z-index: 0;
  font-weight: 535;
  border-radius: 16px;
  width: 100%;
`

const Wrapper = styled(Column)`
  border: 1px solid ${({ theme }) => theme.surface3};
  border-radius: 16px;
  padding: 12px 16px;
`

export function Swap({ defaultInputTokenIndex = -1, defaultOutputTokenIndex = -1 }: { defaultInputTokenIndex?: number, defaultOutputTokenIndex?: number }) {
    const lastBlock = useLastBlock();
    const [rerender, setRerender] = useState(false);
    const [swapStatus, setSwapStatus] = useState(0);
    const [transactionHash, setTransactionHash] = useState("");
    const isDark = useIsDarkMode()
    const theme = useTheme()

    const location = useLocation()
    const isSwapPage = location.pathname.includes('swap')

    const [, toggleAccountDrawer] = useAccountDrawer()
    /*const defaultInputIndex = getMergedTokenList().findIndex(x => x?.address === "0x1::aptos_coin::AptosCoin");*/
    const [inputToken, setInputToken] = useState(defaultInputTokenIndex);
    const [outputToken, setOutputToken] = useState(defaultOutputTokenIndex);

    const [inputAmount, setInputAmount] = useState("");
    const [outputAmount, setOutputAmount] = useState("");

    const [inputBalance, setInputBalance] = useState(0);
    const [outputBalance, setOutputBalance] = useState(0);

    const [networkCost, setNetworkCost] = useState(0);
    const [priceImpact, setPriceImpact] = useState("");
    const [showPriceImpactModal, setShowPriceImpactModal] = useState(false);
    /*const [inputNotSelected, setInputNotSelected] = useState(defaultInputTokenIndex === -1);
    const [receiveNotSelected, setReceiveNotSelected] = useState(defaultOutputTokenIndex === -1);*/
    const [swappedTokens, setSwappedTokens] = useState(false);

    const [isInputRegistered, setIsInputRegistered] = useState<boolean>(false);
    const [isOutputRegistered, setIsOutputRegistered] = useState<boolean>(false);

    const [tokenPairMetadata, setTokenPairMetadata] = useState<TokenPairMetadataType>();

    const [isLastEditInput, setIsLastEditInput] = useState<boolean>(true);

    const inputNotSelected = useMemo(() => inputToken === -1, [inputToken]);
    const receiveNotSelected = useMemo(() => outputToken === -1, [outputToken]);

    const {
        connect,
        account,
        network,
        connected,
        disconnect,
        wallet,
        wallets,
        signAndSubmitTransaction,
        signTransaction,
        signMessage,
        signMessageAndVerify,
    } = useWallet();

    const aptosClient = new AptosClient(getNetworkURL(), {
        WITH_CREDENTIALS: false,
    });

    const submitAndUpdate = async (payload: any) => {
        try {
            setSwapStatus(1);
            const response = await signAndSubmitTransaction(payload);
            setTransactionHash(response?.hash || "");
            // if you want to wait for transaction
            await aptosClient.waitForTransaction(response?.hash || "");
            setSwapStatus(2);
            setTimeout(function(){
                setInputAmount("");
                setOutputAmount("");
                setSwapStatus(0);
                setRerender(!rerender);
            }, 5000);
        } catch (error: any) {
            setSwapStatus(0);
            console.log("error", error);
        }
    };

    /*const [userSlippageTolerance] = useUserSlippageTolerance();
    const { numerator, denominator } = (userSlippageTolerance as Percent) || {}
    const slippageValue = numerator ? Number(numerator?.toString()) / 100 : 0.005;

    console.log(slippageValue);*/

    const [userSlippageTolerance] = useUserSlippageTolerance();
    const { numerator, denominator } = (userSlippageTolerance as Percent) || {}
    const slippageValue = Number(numerator?.toString()) / Number(denominator?.toString());
    const slippageNumber = Number.isNaN(slippageValue) ? 0.49 : slippageValue;

    const priceImpactNumber = useMemo(() => {
        return (Number(priceImpact) || 0) / 100;
    }, [priceImpact]);

    const onSwap = async () => {
        /*const [userSlippageTolerance] = useUserSlippageTolerance();
        const { numerator, denominator } = (userSlippageTolerance as Percent) || {}
        const slippageValue = Number(numerator?.toString()) / Number(denominator?.toString());
        const slippageNumber = Number.isNaN(slippageValue) ? 0.005 : slippageValue;*/

        /*if(getMergedTokenList()[inputToken].address === "0x83b619e2d9e6e10d15ed4b714111a4cd9526c1c2ae0eec4b252a619d3e8bdda3::MAU::MAU" ||
            getMergedTokenList()[outputToken].address === "0x83b619e2d9e6e10d15ed4b714111a4cd9526c1c2ae0eec4b252a619d3e8bdda3::MAU::MAU") return;*/

        /*if (isLastEditInput) {
            let payload = {
                type: "entry_function_payload",
                function: getSmartContractFunction("swap_exact_input"),
                type_arguments: [getMergedTokenList()[inputToken].address, getMergedTokenList()[outputToken].address],
                arguments:
                    [(Number(inputAmount) * 10 ** getMergedTokenList()[inputToken].decimals).toFixed(0),
                        (Number(outputAmount) * 10 ** getMergedTokenList()[outputToken].decimals * (1 - slippageNumber) * (1 - priceImpactNumber)).toFixed(0)]
            };
            submitAndUpdate(payload);
        }
        else {
            let payload = {
                type: "entry_function_payload",
                function: getSmartContractFunction("swap_exact_output"),
                type_arguments: [getMergedTokenList()[inputToken].address, getMergedTokenList()[outputToken].address],
                arguments: [
                    (Number(outputAmount) * 10 ** getMergedTokenList()[outputToken].decimals).toFixed(0),
                    (Number(inputAmount) * 10 ** getMergedTokenList()[inputToken].decimals * (1 + slippageNumber) * (1 + priceImpactNumber)).toFixed(0),
                ],
            };
            submitAndUpdate(payload);
        }*/



        if (isLastEditInput) {
            let payload = {
                sender: account?.address || "",
                data: {
                    function: getSmartContractFunction("swap_exact_input"),
                    typeArguments: [getMergedTokenList()[inputToken].address, getMergedTokenList()[outputToken].address],
                    functionArguments: [
                        (Number(inputAmount) * 10 ** getMergedTokenList()[inputToken].decimals).toFixed(0),
                        (Number(outputAmount) * 10 ** getMergedTokenList()[outputToken].decimals * (1 - slippageNumber) * (1 - priceImpactNumber)).toFixed(0)
                    ],
                },
            };
            submitAndUpdate(payload);
        }
        else {
            let payload = {
                sender: account?.address || "",
                data: {
                    function: getSmartContractFunction("swap_exact_output"),
                    typeArguments: [getMergedTokenList()[inputToken].address, getMergedTokenList()[outputToken].address],
                    functionArguments: [
                        (Number(outputAmount) * 10 ** getMergedTokenList()[outputToken].decimals).toFixed(0),
                        (Number(inputAmount) * 10 ** getMergedTokenList()[inputToken].decimals * (1 + slippageNumber) * (1 + priceImpactNumber)).toFixed(0),
                    ],
                },
            };
            submitAndUpdate(payload);
        }
    };

   /* const onSignAndSubmitTransaction = async () => {
        const faucetClient = new AptosFaucetClient({BASE: "https://faucet.testnet.aptoslabs.com"});
        const amount = 5;
        const address = "0xe7a10a6349ded659e0acdc7be6977cc99814ff085dc19bf76f8b75dbd83dc38b";
        const request: FundRequest = {
            amount,
            address,
        };
        // @ts-ignore
        const response = await faucetClient.fund({ requestBody: request });
        /!*await aptosClient.waitForTransaction(response?.hash || "");*!/
    };*/

    const swapState = useSelector((state: AppState) => state.swapReducer);

    useEffect(() => {
        if (swapState[Field.INPUT].currencyId) {
            const tokenIndex = getMergedTokenList().findIndex((token) => {
                return token.address === swapState[Field.INPUT].currencyId;
            });
            setInputToken((prevState) => tokenIndex === -1 ? prevState : tokenIndex);
        }
    }, [swapState]);

    const onRegisterToken = async (isInputToken: boolean) => {
        /*const payload: Types.TransactionPayload = {
            type: "entry_function_payload",
            function: "0x1::managed_coin::register",
            type_arguments: [getMergedTokenList()[isInputToken ? inputToken :outputToken].address],
            arguments: [],
        };*/

        const payload: any = {
            sender: account?.address,
            data: {
                function: "0x1::managed_coin::register",
                typeArguments: [getMergedTokenList()[isInputToken ? inputToken :outputToken].address],
                functionArguments: [],
            },
        };

        try {
            setSwapStatus(1);
            const response = await signAndSubmitTransaction(payload);
            setTransactionHash(response?.hash || "");
            await aptosClient.waitForTransaction(response?.hash || "");
            if(isInputToken) setIsInputRegistered(true);
            else setIsOutputRegistered(true);
            setSwapStatus(2);
            setTimeout(function(){
                setSwapStatus(0);
                setRerender(!rerender);
            }, 5000);
        } catch (error: any) {
            setSwapStatus(0);
            console.log("error", error);
        }
    };

    useEffect(() => {
    
        getTokenPairMetadata(getMergedTokenList()?.[inputToken]?.address, getMergedTokenList()?.[outputToken]?.address).then(res => {
            if(res.data) {
                let data = res.data;
                let metadata: TokenPairMetadataType = {
                    balance_x: data.balance_x.value,
                    balance_y: data.balance_y.value,
                    liquidity_fee: data.liquidity_fee,
                    team_fee: data.team_fee,
                    rewards_fee: data.rewards_fee,
                };
                setTokenPairMetadata(metadata);
            }else {
                getTokenPairMetadata(getMergedTokenList()?.[outputToken]?.address, getMergedTokenList()?.[inputToken]?.address).then(res => {
                    if(res.data) {
                        let data = res.data;
                        let metadata: TokenPairMetadataType = {
                            balance_x: data.balance_y.value,
                            balance_y: data.balance_x.value,
                            liquidity_fee: data.liquidity_fee,
                            team_fee: data.team_fee,
                            rewards_fee: data.rewards_fee,
                        };
                        setTokenPairMetadata(metadata);
                    }else {
                        setTokenPairMetadata(undefined);
                    }
                });
            }
        });
    }, [inputToken, outputToken, rerender, lastBlock]);

    useEffect(() => {
        if (!connected) {
            setInputBalance(0);
            setOutputBalance(0);
        }

        if (connected && account) {
            if (getMergedTokenList()[inputToken]?.address) {
                getAccountCoinValue(account.address, getMergedTokenList()[inputToken].address).then(res => {
                    if(res.data) {
                        const value = res.data.coin.value;
                        setInputBalance(formatBalance(value, getMergedTokenList()[inputToken].decimals));
                        setIsInputRegistered(true);
                    }else {
                        setInputBalance(0);
                        setIsInputRegistered(false);
                    }
                });
            }

            if (getMergedTokenList()[outputToken]?.address) {
                getAccountCoinValue(account.address, getMergedTokenList()[outputToken].address).then(res => {
                    if(res.data) {
                        const value = res.data.coin.value;
                        setOutputBalance(formatBalance(value, getMergedTokenList()[outputToken].decimals));
                        setIsOutputRegistered(true);
                    }else {
                        setOutputBalance(0);
                        setIsOutputRegistered(false);
                    }
                });
            }
        }
    }, [connected, inputToken, outputToken, account, rerender, lastBlock]);

    useEffect(() => {
        if(!isLastEditInput)
        if(isValidPairMetadata(tokenPairMetadata)) {
            const inputValue = calculateRate(
                outputAmount,
                (Number(tokenPairMetadata.balance_x) *
                    10 ** getMergedTokenList()[outputToken].decimals) /
                (Number(tokenPairMetadata.balance_y) *
                    10 ** getMergedTokenList()[inputToken].decimals)
            );
            if(inputValue === 0) setInputAmount("");
            else setInputAmount(inputValue.toFixed(2).toString());
        }
    }, [tokenPairMetadata, inputToken, outputToken, outputAmount]);

    useEffect(() => {
        if(isLastEditInput)
        if(isValidPairMetadata(tokenPairMetadata)) {
            const outputValue = calculateRate(inputAmount,
                (Number(tokenPairMetadata.balance_y) *
                    10 ** getMergedTokenList()[inputToken].decimals) /
                (Number(tokenPairMetadata.balance_x) *
                    10 ** getMergedTokenList()[outputToken].decimals)
            );
            if(outputValue === 0) setOutputAmount("");
            else setOutputAmount(outputValue.toFixed(2).toString());
        }
    }, [tokenPairMetadata, inputAmount, inputToken, outputToken]);

    const onInputAmount = useCallback((value: string) => {
        setInputAmount(value);
        setIsLastEditInput(true);
        if(isValidPairMetadata(tokenPairMetadata)) {
            const outputValue = calculateRate(value,
                (Number(tokenPairMetadata.balance_y) *
                    10 ** getMergedTokenList()[inputToken].decimals) /
                (Number(tokenPairMetadata.balance_x) *
                    10 ** getMergedTokenList()[outputToken].decimals)
            );
            if(outputValue === 0) setOutputAmount("");
            else setOutputAmount(outputValue.toFixed(2).toString());
        }
    }, [tokenPairMetadata, inputToken, outputToken]);

    const onOutputAmount = useCallback((value: string) => {
        setOutputAmount(value);
        setIsLastEditInput(false);
        if(isValidPairMetadata(tokenPairMetadata)) {
            const inputValue = calculateRate(
                value,
                (Number(tokenPairMetadata.balance_x) *
                    10 ** getMergedTokenList()[outputToken].decimals) /
                (Number(tokenPairMetadata.balance_y) *
                    10 ** getMergedTokenList()[inputToken].decimals)
            );
            if(inputValue === 0) setInputAmount("");
            else setInputAmount(inputValue.toFixed(2).toString());
        }
    }, [tokenPairMetadata, inputToken, outputToken]);

    const swapTokens = useCallback(() => {
        let prevInputToken = inputToken;
        let prevOutputToken = outputToken;
        setInputToken(prevOutputToken);
        setOutputToken(prevInputToken);

        let prevInputAmount = inputAmount;
        let prevOutputAmount = outputAmount;
        setInputAmount(prevOutputAmount);
        setOutputAmount(prevInputAmount);

        /*if(isLastEditInput) onOutputAmount(inputAmount);
        else onInputAmount(outputAmount);*/
        setSwappedTokens(e => !e);
    }, [inputToken, outputToken, inputAmount, outputAmount]);

    const onInputCurrencySelect = useCallback((currency: Currency) => {
        const tokenIndex = getMergedTokenList().findIndex((token) => {
          return token.address === (currency as any).address;
        });

        if(outputToken === tokenIndex) {
            swapTokens();
            return;
        }

        setInputToken((prevState) => (tokenIndex === -1 ? prevState : tokenIndex));

        /*if(swappedTokens) setReceiveNotSelected(false);*/
    }, [setInputToken, swappedTokens, outputToken]);

    const onOutputCurrencySelect = useCallback((currency: Currency) => {
        const tokenIndex = getMergedTokenList().findIndex((token) => {
            return token.address === (currency as any).address;
        });

        if(inputToken === tokenIndex) {
            swapTokens();
            return;
        }
        
        /*setReceiveNotSelected(false);*/
        setOutputToken((prevState) => (tokenIndex === -1 ? prevState : tokenIndex));
    }, [setOutputToken, swappedTokens, inputToken]);

    const onMaxInput = useCallback(() => {
        let maxInput = "0";
        if(getMergedTokenList()[inputToken]?.address === APT_TOKEN_ADDRESS) maxInput = (inputBalance - (networkCost * 10)).toString();
        else maxInput = (inputBalance).toString();
        onInputAmount(maxInput);
    }, [inputBalance, networkCost, inputToken]);

    const onInputButtonsClick = useCallback((value: number) => {
        let maxInput = 0;
        if(getMergedTokenList()[inputToken]?.address === APT_TOKEN_ADDRESS) maxInput = inputBalance - (networkCost * 10);
        else maxInput = inputBalance;

        onInputAmount(toFixedDown(maxInput * (value / 100), 5).toString());
        /*onInputAmount(Number((maxInput * (value / 100)).toFixed(5)).toString());*/
    }, [inputBalance, networkCost, inputToken]);

    const mainButton = () => {
        switch (true) {
            case !connected:
                return <StyledButtonLight onClick={()=>toggleAccountDrawer()}>
                    <Trans>Connect wallet</Trans>
                </StyledButtonLight>;
            case inputNotSelected:
                return (<StyledButtonLight onClick={()=>{}} disabled={true}>
                    Select input token
                </StyledButtonLight>);
            case receiveNotSelected:
                return (<StyledButtonLight onClick={()=>{}} disabled={true}>
                            Select output token
                        </StyledButtonLight>);
            case !tokenPairMetadata:
                return (<StyledButtonLight onClick={()=>{}} disabled={true}>
                            <Trans>Non-existent pair</Trans>
                        </StyledButtonLight>);
            case ((tokenPairMetadata?.balance_y == "0") || (tokenPairMetadata?.balance_x == "0")):
                return (<StyledButtonLight onClick={()=>{}} disabled={true}>
                            <Trans>Non-liquidity pair</Trans>
                        </StyledButtonLight>);
            case !isInputRegistered:
                return (<StyledButtonLight onClick={()=>onRegisterToken(true)}>
                            <Trans>Register {getMergedTokenList()[inputToken].symbol}</Trans>
                        </StyledButtonLight>);
            case !isOutputRegistered:
                return (<StyledButtonLight onClick={()=>onRegisterToken(false)}>
                            <Trans>Register {getMergedTokenList()[outputToken].symbol}</Trans>
                        </StyledButtonLight>);
            case (Number(inputAmount) > inputBalance):
                return (<StyledButtonLight onClick={()=>{}} disabled={true}>
                            <Trans>Insufficient {getMergedTokenList()[inputToken].symbol} balance</Trans>
                        </StyledButtonLight>);
            case (!inputAmount || !outputAmount):
                return (<StyledButtonLight onClick={()=>{}} disabled={true}>
                    <Trans>Enter an amount</Trans>
                </StyledButtonLight>);
            case (!!inputAmount && Number(priceImpact) >= 15):
                return (<StyledButtonRed
                    size={ButtonSize.large}
                    emphasis={ButtonEmphasis.destructive}
                    onClick={() => setShowPriceImpactModal(true)}>
                    Swap anyway
                </StyledButtonRed>);
            default:
                return (<StyledButtonLight onClick={onSwap}>
                            <Trans>Swap</Trans>
                        </StyledButtonLight>);
        }
    }

    return (
        <>{isSwapPage && <SwapBg/>}
            <SwapWrapper isDark={isDark}>
                
                <SwapHeader/>
                {Boolean(swapStatus) && <PendingModalContent transactionStatus={swapStatus} transactionHash={transactionHash}/>}
                <div style={{ display: 'relative' }}>
                    <SwapSection>
                        <Trace>
                            <SwapCurrencyInputPanel
                                onMax={onMaxInput}
                                label={<Trans>You pay</Trans>}
                                value={inputAmount}
                                currency={inputToken}
                                onUserInput={onInputAmount}
                                onCurrencySelect={onInputCurrencySelect}
                                balance={inputBalance}
                                notSelected={inputNotSelected}
                                canMax={false}
                                disableInput={receiveNotSelected || inputNotSelected}
                                hideMax={receiveNotSelected || inputNotSelected}
                                hideForceBalance={swappedTokens && inputNotSelected}

                                showMaxButtons={!receiveNotSelected && !inputNotSelected && connected}
                                onInputButtonsClick={onInputButtonsClick}
                            />
                        </Trace>
                    </SwapSection>
                    <ArrowWrapper clickable={true}>
                        <ArrowContainer
                            data-testid="swap-currency-button"
                            onClick={swapTokens}
                            color={theme.neutral1}
                        >
                            <ArrowDown size="16" color={theme.neutral1}/>
                        </ArrowContainer>
                    </ArrowWrapper>
                </div>
                <AutoColumn gap="xs">
                    <div>
                        <OutputSwapSection>
                            <Trace>
                                <SwapCurrencyInputPanel
                                    onMax={() => onOutputAmount(outputBalance.toString())}
                                    label={<Trans>You receive</Trans>}
                                    value={outputAmount}
                                    currency={outputToken}
                                    onUserInput={onOutputAmount}
                                    onCurrencySelect={onOutputCurrencySelect}
                                    balance={outputBalance}
                                    notSelected={receiveNotSelected}
                                    canMax={false}
                                    disableInput={receiveNotSelected || inputNotSelected}
                                    hideMax={receiveNotSelected || inputNotSelected}
                                    hideForceBalance={swappedTokens && receiveNotSelected}
                                />
                            </Trace>
                        </OutputSwapSection>
                    </div>
                    <SwapDetails
                        tokenPairMetadata={tokenPairMetadata}
                        inputAmount={inputAmount}
                        inputToken={inputToken}
                        outputAmount={outputAmount}
                        outputToken={outputToken}
                        isLastEditInput={isLastEditInput}
                        setPriceImpact={(impact) => {
                            if(impact) setPriceImpact(impact)
                        }}
                        networkCost={networkCost}
                        setNetworkCost={setNetworkCost}
                    />
                    {(priceImpact && Number(priceImpact) >= 15) && <PriceImpactWarning priceImpact={priceImpact} />}

                    <div>
                        {mainButton()}
                    </div>
                </AutoColumn>
            </SwapWrapper>
            {showPriceImpactModal && <PriceImpactModal 
                priceImpact={priceImpact}
                onDismiss={() => setShowPriceImpactModal(false)}
                onContinue={() => {
                    setShowPriceImpactModal(false);
                    onSwap();
                }}
            />}
        </>
    )
}