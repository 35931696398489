export enum Field {
  INPUT = "INPUT",
  OUTPUT = "OUTPUT",
}

export interface SwapState {
  readonly independentField: Field;
  readonly [Field.INPUT]: {
    readonly currencyId?: string | null;
  };
  readonly [Field.OUTPUT]: {
    readonly currencyId?: string | null;
  };
}

export const initialState: SwapState = {
  independentField: Field.INPUT,
  [Field.INPUT]: { currencyId: null },
  [Field.OUTPUT]: { currencyId: null },
};

export interface SwapPayload {
  field: Field;
  currencyId: string;
}

export interface SwapAction {
  type: string;
  payload: SwapPayload;
}

export const swapReducer = (state = initialState, action: SwapAction) => {
  switch (action.type) {
    case "SWAP":
      return {
        ...state,
        [action.payload.field]: { currencyId: action.payload.currencyId },
      };
    default:
      return state;
  }
};
