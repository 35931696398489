import {ThemedText} from "../../theme/components";
import {Trans} from "@lingui/macro";
import {RowBetween, RowFixed} from "../../components/Row";
import styled from "styled-components";
import SettingsTab from "components/Settings";
import { Percent } from '@uniswap/sdk-core'
import {useNavigate, useParams} from "react-router-dom";
import {useCallback, useMemo} from "react";
import {getMergedTokenList} from "../../constants/tokenList";

const StyledSwapHeader = styled(RowBetween)`
  margin-bottom: 10px;
  color: ${({ theme }) => theme.neutral2};
`

const HeaderButtonContainer = styled(RowFixed)`
  padding: 0 12px;
  gap: 16px;
`
const SubHeader = styled(ThemedText.SubHeader)<{ active?: boolean }>`
  color: ${({ active, theme }) => (active ? theme.neutral1 : theme.neutral2)};
  cursor: pointer;
  user-select: none;
  
  &:hover {
    opacity: 0.9;
  }
`

export default function SwapHeader() {
    const { tokenAddress, chainName } = useParams<{
        tokenAddress: string
        chainName?: string
    }>()

    const isTokensPage = location.pathname.includes('tokens');

    const navigate = useNavigate();

    const token = useMemo(() => {
        return getMergedTokenList().find(el => el.address === tokenAddress);
    }, [tokenAddress]);

    const navigateToEarnPage = useCallback(() => {
        navigate(`/earn${token?.name ? `?search=${token?.name}` : ""}`);
    }, [navigate]);

    return (
        <StyledSwapHeader>
            <HeaderButtonContainer>
                {isTokensPage ? (<><SubHeader active={true}>
                        <Trans>Swap</Trans>
                    </SubHeader>
                        <SubHeader onClick={navigateToEarnPage}>
                            <Trans>Earn</Trans>
                        </SubHeader></>
                ) : (<ThemedText.SubHeader>
                    <Trans>Swap</Trans>
                </ThemedText.SubHeader>)}
            </HeaderButtonContainer>
            <RowFixed>
                <SettingsTab autoSlippage={new Percent(5, 1000)}/>
            </RowFixed>
        </StyledSwapHeader>
    )
}