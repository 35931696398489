export const MOVEMENT_DEVNET_NODE_URL = "https://aptos.devnet.m1.movementlabs.xyz";
export const DEVNET_NODE_URL = "https://fullnode.devnet.aptoslabs.com/v1";
export const TESTNET_NODE_URL = "https://fullnode.testnet.aptoslabs.com/v1";
export const MAINNET_NODE_URL = "https://fullnode.mainnet.aptoslabs.com/v1";
export const MAINNET_INDEXER_GRAPHQL = "https://indexer.mainnet.aptoslabs.com/v1/graphql";
export const TESTNET_INDEXER_GRAPHQL = "https://indexer-testnet.staging.gcp.aptosdev.com/v1/graphql";
export const MOVEMENT_DEVNET_INDEXER_GRAPHQL = "https://indexer-testnet.staging.gcp.aptosdev.com/v1/graphql";
export const SWAP_ADDRESS_MAINNET = "0xe52923154e25c258d9befb0237a30b4001c63dc3bb73011c29cb3739befffcef";
export const SWAP_ADDRESS_MAINNET_OLD = "0x6ee5ff12d9af89de4cb9f127bc4c484d26acda56c03536b5e3792eac94da0a36";
export const SWAP_ADDRESS_TESTNET = "0x58e119832e253f9bba2192d916e1837234d4cf32dfc7dc55615c1195ac2447cd";
export const SWAP_ADDRESS_MOVEMENT_DEVNET = "0xd422bfd5d2ecf14cfd3fc45aae225b396bcbe4d7fcf8c093a5da953a368c28e7";
export const TOKEN_DEPLOYER_ADDRESS_MAINNET = "0xa55a7122aa085e4bf34b755675fb900c40ee92f60c068255609ecea8ef4123f3";
export const TOKEN_DEPLOYER_ADDRESS_TESTNET = "0x7a0393d74bfe947407decab081bd12e4bc540510f26a83d4bc11b69911e0ae69";
export const TOKEN_DEPLOYER_ADDRESS_MOVEMENT_DEVNET = "0x4dfbdb89ec2e6f9cf082df0fc8b4b95b0d9b4406a686b8f39bfd39ef1bb030e6";
export const APT_TOKEN_ADDRESS = "0x1::aptos_coin::AptosCoin";
export const APTOSLABS_URL = "https://explorer.aptoslabs.com";
export const MOVEMENTLABS_URL = "https://explorer.movementlabs.xyz";

export const networks = ["Mainnet", "Mainnet (old)", "Testnet", "Movement Devnet"];

export const smartContractFunctions: { [index: string]: any } = {
    mainnet: {
        swapSection: "swap_v2dot1",
        stakeSection: "stake_v2dot1",

        swap_exact_input: `${SWAP_ADDRESS_MAINNET}::router_v2dot1::swap_exact_input`,
        swap_exact_output: `${SWAP_ADDRESS_MAINNET}::router_v2dot1::swap_exact_output`,

        stake_tokens_in_pool: `${SWAP_ADDRESS_MAINNET}::router_v2dot1::stake_tokens_in_pool`,
        unstake_tokens_from_pool: `${SWAP_ADDRESS_MAINNET}::router_v2dot1::unstake_tokens_from_pool`,
        claim_rewards_from_pool: `${SWAP_ADDRESS_MAINNET}::router_v2dot1::claim_rewards_from_pool`,

        create_pair: `${SWAP_ADDRESS_MAINNET}::router_v2dot1::create_pair`,
        add_liquidity: `${SWAP_ADDRESS_MAINNET}::router_v2dot1::add_liquidity`,
        remove_liquidity: `${SWAP_ADDRESS_MAINNET}::router_v2dot1::remove_liquidity`,

        initialize_fee_on_transfer: `${SWAP_ADDRESS_MAINNET}::fee_on_transfer_v2dot1::initialize_fee_on_transfer`,
        set_liquidity_fee: `${SWAP_ADDRESS_MAINNET}::fee_on_transfer_v2dot1::set_liquidity_fee`,
        set_team_fee: `${SWAP_ADDRESS_MAINNET}::fee_on_transfer_v2dot1::set_team_fee`,
        set_rewards_fee: `${SWAP_ADDRESS_MAINNET}::fee_on_transfer_v2dot1::set_rewards_fee`,
        set_all_fees: `${SWAP_ADDRESS_MAINNET}::fee_on_transfer_v2dot1::set_all_fees`,

        get_owner: `${SWAP_ADDRESS_MAINNET}::fee_on_transfer_v2dot1::get_owner`,
        get_info: `${SWAP_ADDRESS_MAINNET}::fee_on_transfer_v2dot1::get_info`,
        get_dex_liquidity_fee: `${SWAP_ADDRESS_MAINNET}::swap_v2dot1::get_dex_liquidity_fee`,
        get_dex_treasury_fee: `${SWAP_ADDRESS_MAINNET}::::swap_v2dot1::get_dex_treasury_fee`,
        get_dex_fees: `${SWAP_ADDRESS_MAINNET}::admin_v2dot1::get_dex_fees`,
        get_all_fee_on_transfer: `${SWAP_ADDRESS_MAINNET}::fee_on_transfer_v2dot1::get_all_fee_on_transfer`,
    },
    "mainnet (old)": {
        swapSection: "swap_v2",
        stakeSection: "stake",

        swap_exact_input: `${SWAP_ADDRESS_MAINNET_OLD}::router_v2::swap_exact_input`,
        swap_exact_output: `${SWAP_ADDRESS_MAINNET_OLD}::router_v2::swap_exact_output`,

        stake_tokens_in_pool: `${SWAP_ADDRESS_MAINNET_OLD}::router_v2::stake_tokens_in_pool`,
        unstake_tokens_from_pool: `${SWAP_ADDRESS_MAINNET_OLD}::router_v2::unstake_tokens_from_pool`,
        claim_rewards_from_pool: `${SWAP_ADDRESS_MAINNET_OLD}::router_v2::claim_rewards_from_pool`,

        create_pair: `${SWAP_ADDRESS_MAINNET_OLD}::router_v2::create_pair`,
        add_liquidity: `${SWAP_ADDRESS_MAINNET_OLD}::router_v2::add_liquidity`,
        remove_liquidity: `${SWAP_ADDRESS_MAINNET_OLD}::router_v2::remove_liquidity`,

        initialize_fee_on_transfer: `${SWAP_ADDRESS_MAINNET_OLD}::fee_on_transfer::initialize_fee_on_transfer`,
        set_liquidity_fee: `${SWAP_ADDRESS_MAINNET_OLD}::fee_on_transfer::set_liquidity_fee`,
        set_team_fee: `${SWAP_ADDRESS_MAINNET_OLD}::fee_on_transfer::set_team_fee`,
        set_rewards_fee: `${SWAP_ADDRESS_MAINNET_OLD}::fee_on_transfer::set_rewards_fee`,

        get_owner: `${SWAP_ADDRESS_MAINNET_OLD}::fee_on_transfer::get_owner`,
        get_info: `${SWAP_ADDRESS_MAINNET_OLD}::fee_on_transfer::get_info`,
        get_dex_liquidity_fee: `${SWAP_ADDRESS_MAINNET_OLD}::swap_v2::get_dex_liquidity_fee`,
        get_dex_treasury_fee: `${SWAP_ADDRESS_MAINNET_OLD}::::swap_v2::get_dex_treasury_fee`,
        get_dex_fees: `${SWAP_ADDRESS_MAINNET_OLD}::admin::get_dex_fees`,
        get_all_fee_on_transfer: `${SWAP_ADDRESS_MAINNET_OLD}::fee_on_transfer::get_all_fee_on_transfer`,
    },
    testnet: {
        swapSection: "swap_v2dot1",
        stakeSection: "stake_v2dot1",

        swap_exact_input: `${SWAP_ADDRESS_TESTNET}::router_v2dot1::swap_exact_input`,
        swap_exact_output: `${SWAP_ADDRESS_TESTNET}::router_v2dot1::swap_exact_output`,

        stake_tokens_in_pool: `${SWAP_ADDRESS_TESTNET}::router_v2dot1::stake_tokens_in_pool`,
        unstake_tokens_from_pool: `${SWAP_ADDRESS_TESTNET}::router_v2dot1::unstake_tokens_from_pool`,
        claim_rewards_from_pool: `${SWAP_ADDRESS_TESTNET}::router_v2dot1::claim_rewards_from_pool`,

        create_pair: `${SWAP_ADDRESS_TESTNET}::router_v2dot1::create_pair`,
        add_liquidity: `${SWAP_ADDRESS_TESTNET}::router_v2dot1::add_liquidity`,
        remove_liquidity: `${SWAP_ADDRESS_TESTNET}::router_v2dot1::remove_liquidity`,

        initialize_fee_on_transfer: `${SWAP_ADDRESS_TESTNET}::fee_on_transfer_v2dot1::initialize_fee_on_transfer`,
        set_liquidity_fee: `${SWAP_ADDRESS_TESTNET}::fee_on_transfer_v2dot1::set_liquidity_fee`,
        set_team_fee: `${SWAP_ADDRESS_TESTNET}::fee_on_transfer_v2dot1::set_team_fee`,
        set_rewards_fee: `${SWAP_ADDRESS_TESTNET}::fee_on_transfer_v2dot1::set_rewards_fee`,
        set_all_fees: `${SWAP_ADDRESS_TESTNET}::fee_on_transfer_v2dot1::set_all_fees`,

        get_owner: `${SWAP_ADDRESS_TESTNET}::fee_on_transfer_v2dot1::get_owner`,
        get_info: `${SWAP_ADDRESS_TESTNET}::fee_on_transfer_v2dot1::get_info`,
        get_dex_liquidity_fee: `${SWAP_ADDRESS_TESTNET}::swap_v2dot1::get_dex_liquidity_fee`,
        get_dex_treasury_fee: `${SWAP_ADDRESS_TESTNET}::::swap_v2dot1::get_dex_treasury_fee`,
        get_dex_fees: `${SWAP_ADDRESS_TESTNET}::admin_v2dot1::get_dex_fees`,
        get_all_fee_on_transfer: `${SWAP_ADDRESS_TESTNET}::fee_on_transfer_v2dot1::get_all_fee_on_transfer`,
    },
    "movement devnet": {
        swapSection: "swap_v2dot1",
        stakeSection: "stake_v2dot1",

        swap_exact_input: `${SWAP_ADDRESS_MOVEMENT_DEVNET}::router_v2dot1::swap_exact_input`,
        swap_exact_output: `${SWAP_ADDRESS_MOVEMENT_DEVNET}::router_v2dot1::swap_exact_output`,

        stake_tokens_in_pool: `${SWAP_ADDRESS_MOVEMENT_DEVNET}::router_v2dot1::stake_tokens_in_pool`,
        unstake_tokens_from_pool: `${SWAP_ADDRESS_MOVEMENT_DEVNET}::router_v2dot1::unstake_tokens_from_pool`,
        claim_rewards_from_pool: `${SWAP_ADDRESS_MOVEMENT_DEVNET}::router_v2dot1::claim_rewards_from_pool`,

        create_pair: `${SWAP_ADDRESS_MOVEMENT_DEVNET}::router_v2dot1::create_pair`,
        add_liquidity: `${SWAP_ADDRESS_MOVEMENT_DEVNET}::router_v2dot1::add_liquidity`,
        remove_liquidity: `${SWAP_ADDRESS_MOVEMENT_DEVNET}::router_v2dot1::remove_liquidity`,

        initialize_fee_on_transfer: `${SWAP_ADDRESS_MOVEMENT_DEVNET}::fee_on_transfer_v2dot1::initialize_fee_on_transfer`,
        set_liquidity_fee: `${SWAP_ADDRESS_MOVEMENT_DEVNET}::fee_on_transfer_v2dot1::set_liquidity_fee`,
        set_team_fee: `${SWAP_ADDRESS_MOVEMENT_DEVNET}::fee_on_transfer_v2dot1::set_team_fee`,
        set_rewards_fee: `${SWAP_ADDRESS_MOVEMENT_DEVNET}::fee_on_transfer_v2dot1::set_rewards_fee`,
        set_all_fees: `${SWAP_ADDRESS_MOVEMENT_DEVNET}::fee_on_transfer_v2dot1::set_all_fees`,

        get_owner: `${SWAP_ADDRESS_MOVEMENT_DEVNET}::fee_on_transfer_v2dot1::get_owner`,
        get_info: `${SWAP_ADDRESS_MOVEMENT_DEVNET}::fee_on_transfer_v2dot1::get_info`,
        get_dex_liquidity_fee: `${SWAP_ADDRESS_MOVEMENT_DEVNET}::swap_v2dot1::get_dex_liquidity_fee`,
        get_dex_treasury_fee: `${SWAP_ADDRESS_MOVEMENT_DEVNET}::::swap_v2dot1::get_dex_treasury_fee`,
        get_dex_fees: `${SWAP_ADDRESS_MOVEMENT_DEVNET}::admin_v2dot1::get_dex_fees`,
        get_all_fee_on_transfer: `${SWAP_ADDRESS_MOVEMENT_DEVNET}::fee_on_transfer_v2dot1::get_all_fee_on_transfer`,
    },

};

export const tokenDeployerFunctions: { [index: string]: any } = {
    mainnet: {
        get_fee: `${TOKEN_DEPLOYER_ADDRESS_MAINNET}::deployer::get_fee`,
    },
    testnet: {
        get_fee: `${TOKEN_DEPLOYER_ADDRESS_TESTNET}::deployer_v2::fee`,
    },
    "Movement Devnet": {
        get_fee: `${TOKEN_DEPLOYER_ADDRESS_MOVEMENT_DEVNET}::deployer::get_fee`,
    },
};

export const localStorageKeyNetwork = "network";

export const denominator = 10000;