import {useEffect, useState} from "react";
import {getLedgerInfo} from "../apiRequests";

export function useLastBlock() {
    const [block, setBlock] = useState("");

    useEffect(() => {
        getLedgerInfo().then((res: any) => {
            setBlock(res.block_height);
        })

        let interval = setInterval(() => {
            getLedgerInfo().then((res: any) => {
                setBlock(res.block_height);
            })
        }, 20000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    return block;
}