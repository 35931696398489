import { Currency } from "@uniswap/sdk-core";
import { local } from "d3";
import { get } from "http";
import {getBlockchainName, getNetwork} from "../utils/sundry";

export const MAU_TOKEN = {
  name: "MAU",
  symbol: "MAU",
  iconSrc: "/external_media/MAU-icon.jpeg",
  address: "0x83b619e2d9e6e10d15ed4b714111a4cd9526c1c2ae0eec4b252a619d3e8bdda3::MAU::MAU",
  decimals: 8,
};

export const FOMO_TOKEN = {
  name: "Aptos FOMO",
  symbol: "FOMO",
  iconSrc: "/external_media/FOMO.svg",
  address: "0xf891d2e004973430cc2bbbee69f3d0f4adb9c7ae03137b4579f7bb9979283ee6::APTOS_FOMO::APTOS_FOMO",
  decimals: 6,
};

export type TokenType = {
  address: string;
  name: string;
  symbol: string;
  synonym?: string;
  iconSrc: string;
  decimals: number;
};

export interface Token {
  coin_type: string;
  decimals: number;
  name: string;
  symbol: string;
}

export const TOKEN_LIST: TokenType[] = [
    {
      name: "Aptos",
      symbol: "APT",
      iconSrc: "/external_media/aptos-bg-black.png",
      address: "0x1::aptos_coin::AptosCoin",
      decimals: 8,
    },
    {
      name: "USDC Coin",
      symbol: "USDC (LayerZero)",
      iconSrc: "https://stage-api.aptools.io/images/usdc-(layerzero).png",
      address: "0xf22bede237a07e121b56d91a491eb7bcdfd1f5907926a9e58338f964a01b17fa::asset::USDC",
      decimals: 6,
    },
    {
      name: "Tether USD",
      symbol: "USDT (LayerZero)",
      iconSrc: "https://stage-api.aptools.io/images/usdt-(layerzero).png",
      address: "0xf22bede237a07e121b56d91a491eb7bcdfd1f5907926a9e58338f964a01b17fa::asset::USDT",
      decimals: 6,
    },
    {
      name: "Wrapped Ether",
      symbol: "WETH (LayerZero)",
      iconSrc: "https://stage-api.aptools.io/images/weth-(layerzero).png",
      address: "0xf22bede237a07e121b56d91a491eb7bcdfd1f5907926a9e58338f964a01b17fa::asset::WETH",
      decimals: 6,
    },
  {
    name: "Apartment",
    symbol: "APARTMENT",
    iconSrc: "/external_media/Apartment.jpg",
    address: "0x7b7bab2131de3e4f318b4abaa952f7c817b2c3df16c951caca809ac9ca9b650e::APARTMENT::APARTMENT",
    decimals: 8,
  },
  {
    name: "Shrimp",
    symbol: "SHRIMP",
    iconSrc: "/external_media/Shrimp.JPG",
    address: "0x36c261fc02ee477fb8ce6dfc3672197f43132c8269959f8a8a52a797a5701181::SHRIMP::SHRIMP",
    decimals: 8,
  },
  {
    name: "ROO",
    symbol: "ROO",
    iconSrc: "/external_media/ROO.jpeg",
    address: "0xd2c9cfe8dd64ebdf9b39e1525997cef33bd178161c59410097d3f3e0704a5df3::ROO::ROO",
    decimals: 8,
  },
  {
    name: "Apto the mascot",
    symbol: "APTO",
    iconSrc: "/external_media/APTO.jpg",
    address: "0x4c71c33b9ec2f263a7f25779bd9a2531165d4da9a963e042a20f288688094a66::APTO_THE_MASCOT::APTO_THE_MASCOT",
    decimals: 8,
  },
];

export const MOVEMENT_TOKEN_LIST: TokenType[] = [
  {
    name: "Move",
    symbol: "MOVE",
    iconSrc: "/external_media/move-bg-black.png",
    address: "0x1::aptos_coin::AptosCoin",
    decimals: 8,
  },
  {
    name: "BAPT USDC",
    symbol: "BAPT_USDC",
    iconSrc: "https://stage-api.aptools.io/images/usdc-(layerzero).png",
    address: "0x31daba5acb5ef0032ef5aaafdeef429c29c7b2132c058615e70ee825fed9cc81::BAPT_USDC::BAPT_USDC",
    decimals: 8,
  },
  {
    name: "BAPT BTC",
    symbol: "BAPT_BTC",
    iconSrc: "/external_media/btc.png",
    address: "0x31daba5acb5ef0032ef5aaafdeef429c29c7b2132c058615e70ee825fed9cc81::BAPT_BTC::BAPT_BTC",
    decimals: 8,
  },
  {
    name: "BAPT ETH",
    symbol: "BAPT_ETH",
    iconSrc: "/external_media/ethereum-eth.svg",
    address: "0x31daba5acb5ef0032ef5aaafdeef429c29c7b2132c058615e70ee825fed9cc81::BAPT_ETH::BAPT_ETH",
    decimals: 8,
  },
];

const localStorageKeyTokenlist = "tokenList";
const localStorageKeyTokenlistMovement = "tokenListMovement";

function getDefaultTokenList() {
  const network = getNetwork();
  if(network === "Mainnet") return [...TOKEN_LIST, FOMO_TOKEN];
  if(network === "Mainnet (old)") return TOKEN_LIST;
  if(network === "Testnet") return TOKEN_LIST;
  if(network === "Movement Devnet") return MOVEMENT_TOKEN_LIST;
  return [];
}

export const getMergedTokenList = (): TokenType[] => {
  /*const network = localStorage.getItem("network") || "Mainnet";*/
  // Retrieve the token list from localStorage, if it exists

  let currentLocalStorageKey = "";
  const blockchain = getBlockchainName();

  if(blockchain === "Aptos") currentLocalStorageKey = localStorageKeyTokenlist;
  if(blockchain === "Movement") currentLocalStorageKey = localStorageKeyTokenlistMovement;

  const storedTokenListString = localStorage.getItem(currentLocalStorageKey);
  const localStorageTokenList: TokenType[] = storedTokenListString
    ? JSON.parse(storedTokenListString)
    : [];

  // Create a new Set to store unique addresses for deduplication
  const uniqueAddresses = new Set(localStorageTokenList.map((token) => token.address));

  // Add tokens from STATIC_TOKEN_LIST that aren't already in localStorageTokenList
  const mergedTokenList = [
    ...getDefaultTokenList().filter((staticToken) => !uniqueAddresses.has(staticToken.address)),
    ...localStorageTokenList,
  ];

  /* return TOKEN_LIST; */
  return mergedTokenList;
};

export const addTokenToStorage = (token: any) => {
  /*if(getBlockchainName() === "Movement") return;*/
  // Retrieve the existing list from localStorage and parse it, or default to an empty array

  let currentLocalStorageKey = "";
  const blockchain = getBlockchainName();

  if(blockchain === "Aptos") currentLocalStorageKey = localStorageKeyTokenlist;
  if(blockchain === "Movement") currentLocalStorageKey = localStorageKeyTokenlistMovement;


  let tokenList: TokenType[] = [];
  try {
    const rawList = localStorage.getItem(currentLocalStorageKey);
    tokenList = rawList ? JSON.parse(rawList) : [];
  } catch (e) {
    console.log(e);
  }

  // Prepare the token object for storage
  const tokenToStore: TokenType = {
    address: token?.coin_type || token?.address,
    name: token.name,
    symbol: token.symbol,
    iconSrc: `https://stage-api.aptools.io/images/${token.symbol?.toLowerCase()}.png`,
    decimals: token.decimals,
  };

  // Check if the token is already in the list to avoid duplicates
  const existingTokenIndex = getMergedTokenList().findIndex(
    (t: TokenType) => t.address === tokenToStore.address
  );
  if (existingTokenIndex >= 0) {
    // Update existing token data
    //tokenList[existingTokenIndex] = tokenToStore;
  } else {
    // Add new token
    tokenList.push(tokenToStore);
  }

  // Save the updated list back to localStorage
  try {
    localStorage.setItem(currentLocalStorageKey, JSON.stringify(tokenList));
  } catch (e) {
    console.log(e);
  }
};

export const addPreparedTokenToStorage = (token: Currency) => {
  // Retrieve the existing list from localStorage and parse it, or default to an empty array
  let tokenList: TokenType[] = [];
  try {
    const rawList = localStorage.getItem(localStorageKeyTokenlist);
    tokenList = rawList ? JSON.parse(rawList) : [];
  } catch (e) {
    console.log(e);
  }

  // Prepare the token object for storage
    const tokenToStore: TokenType = {
      address: (token as any).address || "",
      name: token.name || "",
      symbol: token.symbol || "",
      iconSrc: "",
      decimals: token.decimals,
    };

  // Check if the token is already in the list to avoid duplicates
  const existingTokenIndex = tokenList.findIndex(
    (t: TokenType) => t.address === tokenToStore.address
  );
  if (existingTokenIndex >= 0) {
    // Update existing token data
    //tokenList[existingTokenIndex] = tokenToStore;
  } else {
    // Add new token
    tokenList.push(tokenToStore);
  }

  // Save the updated list back to localStorage
  try {
    localStorage.setItem(localStorageKeyTokenlist, JSON.stringify(tokenList));
  } catch (e) {
    console.log(e);
  }
};
